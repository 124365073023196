import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Carousel } from 'flowbite-react';
import parse from 'html-react-parser';
import constants from 'helpers/constants';


const Home = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const HOME_VIDEO_URL = `${process.env.REACT_APP_FRONT_URL}assets/images/home_video.mp4`;
    const [banner, setBanner] = useState('');
    const [intro, setIntro] = useState('');
    const [services, setServices] = useState('');
    const [service_img, setServiceImg] = useState('');
    const [reviews, setReviews] = useState('');
    useEffect(() => {
        fetchContent();
        fetchReviews();
    }, []);

    const fetchContent = async () => {
        let url = `${API_URL}get-home-data`;
        const cat = await axios.get(url).then(function (response) {
            setServices(response.data.data.services);
            setServiceImg(response.data.data.service_image);
            setBanner(response.data.data.banner_data);
            setIntro(response.data.data.intro);
        }).catch(function (error) {
            console.log('error : ', error);
        });
    }
    const fetchReviews = async () => {
        let url = `${API_URL}get-reviews?is_customer_feedback=1&is_pagination=1&page=1&length=5`;
        const cat = await axios.get(url).then(function (response) {
            setReviews(response.data.data.reviews.data);
        }).catch(function (error) {
            console.log('error : ', error);
        });
    }

    return (
        <>
            <div className='bg-white lg:min-h-[calc(100vh-93px)] h-[300px] md:h-[600px] relative'>
                <img className='h-full w-full object-cover float-left' src={banner?.image_url !== '' ? banner?.image_url : `${process.env.REACT_APP_FRONT_URL}assets/images/home_banner.jpg`} alt="" />
                <img className='md:w-[300px] w-[150px] float-left absolute bottom-1 left-1 z-10' src={`${process.env.REACT_APP_FRONT_URL}assets/images/banner_shape.svg`} alt="" />
                <div className='absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-white flex items-center font-Nunito_SemiBold'>
                    <div className='container m-auto px-4'>
                        {
                            banner !== null && typeof banner !== 'undefined'
                                ?
                                <>
                                    {parse(banner.short_description ? banner.short_description : '')}
                                </>
                                :
                                <>
                                    <p className='text-base uppercase leading-7'>make it special</p>
                                    <h1 className='text-[26px] md:text-[44px] leading-[34px] md:leading-[50px] font-Nunito_Bold text-burgandy py-4'>Delicious & <br />Luxurious Parties</h1>
                                    <p className='text-base leading-7 hidden md:block'>No matter your dreams - we can assist you in<br />planning your WEDDINGS & EVENTS.</p>
                                </>
                        }
                    </div>
                </div>
            </div>

            <div className="clear-both"></div>
            <div className='bg-white float-left w-full md:py-24 py-12 relative'>
                <img className='lg:w-[470px] sm:w-[320px] w-[250px] sm:block hidden float-left absolute bottom-0 right-5' src={`${process.env.REACT_APP_FRONT_URL}assets/images/aboutimg_shap-02.svg`} alt="" />
                <div className='container m-auto px-4'>
                    <div className='flex flex-wrap justify-between items-center'>
                        <div className='float-left w-full md:w-5/12 order-1 mb-10 md:mb-0 relative'>
                            <img className='w-[150px] float-left absolute -top-10 -right-14 md:block hidden' src={`${process.env.REACT_APP_FRONT_URL}assets/images/aboutimg_shap-01.svg`} alt="" />
                            <img className='float-left md:w-auto w-full max-w-full shadow-lg shadow-black/25 relative z-[1]' src={intro?.cms?.image_url ? intro?.cms?.image_url : `${process.env.REACT_APP_FRONT_URL}assets/images/home_about.jpg`} alt="" />
                        </div>
                        <div className='float-right w-full md:w-6/12 font-Nunito_Regular order-2'>
                            {
                                intro?.cms?.long_description !== '' && intro?.cms?.long_description !== null
                                    ?
                                    <>
                                        {parse(intro?.cms?.long_description ? intro?.cms?.long_description : '')}
                                    </>
                                    :
                                    <>
                                        <h2 className='lg:max-w-[500px] lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold'>We have Perfected the art of Catering for Asian Weddings & Parties.</h2>
                                        <span className='w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2'></span>
                                        <div className="clear-both"></div>
                                        <p className='lg:max-w-[400px] text-base text-light_blk leading-7 pb-6'>Preeti Catering is a specialist outdoor catering company creating exquisite Asian cuisine on site with a strong background in working in various different venues and surroundings.</p>
                                    </>
                            }

                            <Link to={`/about-us`} className='text-sm text-burgandy hover:text-black uppercase gap-3 flex items-center tracking-widest'><i className="fa-solid fa-minus"></i> Read More</Link>
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>

            <div className="clear-both"></div>
            {
                services !== '' && typeof services !== 'undefined'
                &&
                <div className='bg-gray-100 float-left w-full md:py-24 py-12 relative'>
                    <img className='w-[210px] float-left absolute bottom-0 left-5 2xl:block hidden' src={`${process.env.REACT_APP_FRONT_URL}assets/images/services_shap1.svg`} alt="" />
                    <div className='container m-auto px-4'>
                        <div className='flex flex-wrap justify-between items-center'>
                            <div className='float-left w-full md:w-5/12 order-1 md:order-2 mb-10 md:mb-0 relative '>
                                <img className='w-[150px] float-left absolute -top-14 xl:-right-20 2xl:block hidden' src={`${process.env.REACT_APP_FRONT_URL}assets/images/services_shap2.svg`} alt="" />
                                <img className='float-left md:w-auto w-full max-w-full shadow-lg shadow-black/25 relative z-[1]' src={service_img?service_img:constants.NO_IMAGE} alt="" />
                            </div>
                            <div className='float-right w-full md:w-6/12 font-Nunito_Regular order-2 md:order-1'>
                                <h2 className='lg:max-w-[500px] lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold'>Our Services</h2>
                                <span className='w-[70px] h-[2px] float-left bg-burgandy mb-6 mt-2'></span>
                                <div className="clear-both"></div>
                                <ul className='float-left w-full'>
                                    {
                                        services !== '' && typeof services !== 'undefined'
                                        &&
                                        services.map((service, index) =>
                                            <li key={index} className='float-left relative pl-[85px] border-b border-gray-200 overflow-hidden w-full my-3'>
                                                <div className='absolute left-0 w-[62px] h-[62px] bg-gray-200 p-4'><img className='float-left w-full' src={service?.image_url ? service?.image_url : constants.NO_IMAGE} alt="" /></div>
                                                <h5 className='text-black text-lg font-Nunito_SemiBold pb-1'>{service?.title}</h5>
                                                <p className='text-base text-light_blk truncate pb-1'>{parse(service?.desc ? service?.desc : '')}</p>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="clear-both"></div>
                    </div>
                </div>
            }


            <div className="clear-both"></div>
            <div className='bg-black lg:min-h-[calc(100vh-100px)] md:h-[600px] h-[300px] relative'>
                <div className='absolute left-0 right-0 top-0 bottom-0 bg-black/25 z-10'></div>
                <video className='h-full w-full absolute object-cover float-left' loop autoPlay muted>
                    <source src={HOME_VIDEO_URL} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <div className="clear-both"></div>
            {
                reviews !== '' && typeof reviews !== 'undefined'
                &&
                <>
                    <div className='bg-gray-100 float-left w-full md:py-24 py-12 relative'>
                        <div className='float-left w-full absolute top-0 bottom-0 left-0 right-0 overflow-hidden'>
                            <img className='w-[230px] float-left absolute -bottom-2 -right-7 md:block hidden' src={`${process.env.REACT_APP_FRONT_URL}assets/images/testi_shap1.svg`} alt="" />
                            <img className='w-[230px] float-left absolute top-20 md:left-[39%] left-[50%]' src={`${process.env.REACT_APP_FRONT_URL}assets/images/testi_shap2.svg`} alt="" />
                        </div>
                        <div className='container m-auto'>
                            <div className='rounded-full w-[80px] h-[80px] leading-[100px] md:w-[140px] md:h-[140px] md:leading-[190px] text-center border-4 border-burgandy absolute md:-top-[70px] -top-[40px] right-5 md:right-auto bg-white z-10'>
                                <i className="fa-solid fa-quote-left md:text-[100px] text-[50px]"></i>
                            </div>
                        </div>
                        <div className='container m-auto px-4 relative z-[1]'>
                            <div className='flex flex-wrap justify-between items-center'>
                                <div className="flex flex-col">
                                    <h2 className='text-lg text-burgandy uppercase tracking-widest'>Testimonials</h2>
                                    <span className='w-[70px] h-[2px] float-left bg-burgandy mb-6 mt-2'></span>
                                    <div className="clear-both"></div>
                                    {/* <h2 className='lg:max-w-[500px] lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold'>What Our<br />Customers Are Saying</h2> */}
                                </div>
                                <div className="flex flex-row w-full mt-8 lg:mt-0 lg:w-6/12 content-end items-center bg-white p-6 text-center">
                                    <Carousel slideInterval={5000} indicators={false} leftControl={<></>} rightControl={<></>}>
                                        {
                                            reviews.map((review, index) =>
                                                <div key={index} className='w-full float-left relative'>
                                                    <p className='font-Nunito_Regular text-base text-light_blk leading-7 pb-4'>{parse(review.long_desc ? review.long_desc : '')}</p>
                                                    <p className='font-Nunito_SemiBold text-base text-black leading-7'><span className='w-[20px] h-[2px] inline-block align-middle bg-burgandy mr-3'></span> {review.testimonial_by}</p>
                                                </div>
                                            )
                                        }
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

        </>
    )
};

export default Home;