const Loader = () => (
  <div className="flex justify-center items-center h-full">
    <div
      className="loader animate-spin rounded-full h-8 w-8 border-t-2 border-b-2"
      style={{ borderColor: "#860144" }}
    ></div>
  </div>
);

export default Loader;
