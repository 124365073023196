import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'flowbite-react';
import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';



const AboutUs = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [banner, setBanner] = useState('');
    const [acheivements, setAcheivements] = useState('');
    const [about, setAbout] = useState('');
    const [mission, setMission] = useState('');
    const [vision, setVision] = useState('');
    useEffect(() => {
        fetchContent();
    }, []);

    const fetchContent = async () => {
        let url = `${API_URL}get-about-us`;
        const cat = await axios.get(url).then(function (response) {
            setBanner(response.data?.data?.banner_data);
            setAcheivements(response?.data?.data?.key_achievement);
            setAbout(response?.data?.data?.aboutUsContent?.cms);
            setMission(response?.data?.data?.aboutUsMission?.cms);
            setVision(response?.data?.data?.aboutUsVision?.cms);
        }).catch(function (error) {
            console.log('error : ', error);
        });
    }
    return (
        <>
            <div className='bg-white lg:h-[600px] h-[300px] relative'>
                <img className='h-full w-full object-cover float-left' src={banner?.image_url !== '' ? banner?.image_url : `${process.env.REACT_APP_FRONT_URL}assets/images/about_banner.jpg`} alt="" />
                <div className='absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-white flex items-center font-Nunito_SemiBold'>
                    <div className='container m-auto px-4'>
                        <Breadcrumb aria-label="Default breadcrumb example">
                            <Link to="/" className='uppercase font-Nunito_SemiBold text-black hover:text-burgandy'>
                                Home
                            </Link>
                            <Breadcrumb.Item className='uppercase'>
                                About Us
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        {
                            banner !== null && typeof banner !== 'undefined'
                                ?
                                <>
                                    {parse(banner.short_description ? banner.short_description : '')}
                                </>
                                :
                                <>
                                    <h1 className='text-[24px] md:text-[36px] leading-[32px] md:leading-[46px] font-Nunito_Bold text-burgandy py-4'>Catering and<br />Event Management Specialists</h1>
                                </>
                        }
                    </div>
                </div>
            </div>

            <div className="clear-both"></div>
            <div className='bg-gray-100 float-left w-full md:py-24 py-12'>
                <div className='container m-auto px-4'>
                    <div className='-mx-3 flex flex-wrap justify-between items-center'>
                        <div className='float-left w-full md:w-5/12 order-1 mb-10 md:mb-0 px-3'>
                            <img className='float-left w-full max-w-full shadow-lg shadow-black/25 rounded-2xl' src={about?.image_url ? about?.image_url : `${process.env.REACT_APP_FRONT_URL}assets/images/about_img_left.jpg`} alt="" />
                        </div>
                        <div className='float-right w-full md:w-6/12 font-Nunito_Regular order-2 px-3 relative'>
                            <img className='w-[80px] float-left absolute -top-14 right-1 z-10' src={`${process.env.REACT_APP_FRONT_URL}assets/images/about_page_shap1.svg`} alt="" />
                            <h2 className='lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold'>{about?.title ? about?.title : ''}</h2>
                            <span className='w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2'></span>
                            <div className="clear-both"></div>
                            {parse(about?.long_description ? about?.long_description : '')}
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>

            <div className="clear-both"></div>
            <div className='bg-white float-left w-full md:py-24 py-12'>
                <div className='container m-auto px-4'>
                    <div className='-mx-3 flex flex-wrap justify-between items-center'>
                        <div className='float-left w-full md:w-5/12 order-1 mb-10 md:mb-0 px-3 md:order-2'>
                            <img className='float-left md:w-auto w-full max-w-full shadow-lg shadow-black/25 rounded-2xl' src={mission?.image_url ? mission?.image_url : `${process.env.REACT_APP_FRONT_URL}assets/images/about_img_right.jpg`} alt="" />
                        </div>
                        <div className='float-right w-full md:w-6/12 font-Nunito_Regular order-2 px-3 md:order-1 relative'>
                            <img className='w-[40px] float-left absolute -top-36 left-1 z-10 md:block hidden' src={`${process.env.REACT_APP_FRONT_URL}assets/images/about_page_shap2.svg`} alt="" />
                            <h2 className='lg:max-w-[500px] lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold'>{mission?.title ? mission?.title : ''}</h2>
                            <span className='w-[70px] h-[2px] float-left bg-burgandy mb-6 mt-2'></span>
                            <div className="clear-both"></div>
                            <div className='cms_pages'>
                                {parse(mission?.long_description ? mission?.long_description : '')}
                            </div>
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>

            <div className="clear-both"></div>
            <div className='bg-gray-100 float-left w-full md:py-24 py-12'>
                <div className='container m-auto px-4'>
                    <div className='-mx-3 flex flex-wrap justify-between items-center'>
                        <div className='float-left w-full md:w-5/12 order-1 mb-10 md:mb-0 px-3'>
                            <img className='float-left w-full max-w-full shadow-lg shadow-black/25 rounded-2xl' src={vision?.image_url ? vision?.image_url : `${process.env.REACT_APP_FRONT_URL}assets/images/about_img_left.jpg`} alt="" />
                        </div>
                        <div className='float-right w-full md:w-6/12 font-Nunito_Regular order-2 px-3 relative'>
                            <img className='w-[60px] float-left absolute md:-top-32 -top-12 right-1 z-10' src={`${process.env.REACT_APP_FRONT_URL}assets/images/about_page_shap3.svg`} alt="" />
                            <h2 className='lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold'>{vision?.title ? vision?.title : ''}</h2>
                            <span className='w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2'></span>
                            <div className="clear-both"></div>
                            <div className='cms_pages'>
                                {parse(vision?.long_description ? vision?.long_description : '')}
                            </div>
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>

            <div className="clear-both"></div>
            {
                acheivements !== '' && typeof acheivements !== 'undefined'
                &&
                <>
                    <div className='bg-white float-left w-full md:py-24 md:pb-10 py-12 pb-6'>
                        <div className='container m-auto px-4 relative'>
                            <img className='md:w-[170px] w-[120px] float-left absolute md:-bottom-10 -bottom-7 right-1 z-10' src={`${process.env.REACT_APP_FRONT_URL}assets/images/acchivment_btm_shap.svg`} alt="" />
                            <div className='float-right w-full font-Nunito_Regular px-3 pb-5 text-center'>
                                <h2 className='lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold'>Key Achievements</h2>
                                <span className='w-[70px] h-[2px] block m-auto bg-burgandy mb-8 mt-2'></span>
                            </div>
                            <div className='float-left w-full relative py-4'>
                                <div className='w-[1px] h-full bg-gray-200 absolute top-0 bottom-0 md:inset-x-1/2 left-[40px]'></div>
                                {
                                    Object.entries(acheivements).map(([key, value], index) =>
                                        <div key={index} className='float-left w-full relative py-5'>
                                            <div className='absolute top-5 md:inset-x-1/2 bg-burgandy w-[80px] h-[80px] leading-[80px] md:-mx-[40px] text-center text-white text-2xl font-Nunito_SemiBold rounded-full'>
                                                {key}
                                            </div>
                                            {
                                                value !== null && value !== ''
                                                &&
                                                <>
                                                    {
                                                        value.map((data, key) =>
                                                            <div key={key} className={`md:w-5/12 sm:w-10/12 w-9/12 float-right ${(index % 2) ? 'md:float-left' : ''} bg-gray-100 p-4 rounded-2xl clear-both mb-6 relative font-Nunito_Regular flex gap-4 lg:items-center lg:flex-row flex-col`}>
                                                                <div className={`${(index % 2) ? 'lg:order-2' : ''}`}>
                                                                    {
                                                                        data?.image_url !== ''
                                                                        &&
                                                                        <img className={`float-left max-w-[115px]`} src={data?.image_url} alt="" />
                                                                    }
                                                                </div>
                                                                <div className={`${(index % 2) ? 'lg:order-1' : ''}`}>
                                                                    {
                                                                        key === 0
                                                                        &&
                                                                        <i className={`${(index % 2) ? 'fa-solid fa-caret-right text-gray-100 absolute md:-right-3.5 md:left-auto top-6 -left-3.5 text-4xl rotate-180 md:rotate-0' : 'fa-solid fa-caret-left text-gray-100 absolute top-6  -left-3.5 text-4xl'}`}></i>
                                                                    }
                                                                    <h3 className='font-Nunito_SemiBold text-lg text-black pb-2'>{data.title}</h3>
                                                                    <p className='text-base text-light_blk'>{data.description}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                </>
            }
        </>

    )
};

export default AboutUs;