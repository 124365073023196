import React, { useEffect, useState, useRef } from "react";
import { Breadcrumb, Label, Radio } from "flowbite-react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import * as Yup from "yup";
import constants from "helpers/constants";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useEffectOnce from "hooks/useEffectOnce";
import ReCAPTCHA from "react-google-recaptcha";
import { WeddingInquirySchema } from "modules/validations/Schema";
import moment from "moment";

const HomeEventInquiry = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [success_msg, setSuccessMsg] = useState("");
  const [error_msg, setErrorMsg] = useState("");
  const [titles, setTitles] = useState("");
  const [events, setEvents] = useState("");
  const [capacities, setCapacities] = useState("");
  const [foods, setFoods] = useState("");
  const [venues, setVenues] = useState("");
  const [proccessing, setProccessing] = useState(false);
  const captchaRef = useRef(null);

  const today =
    new Date().getFullYear() +
    "-" +
    ("0" + (new Date().getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + new Date().getDate()).slice(-2);
  const HomeInquirySchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name!").min(2, "Too Short!"),
    phone: Yup.number("Please enter number only!")
      .required("Please enter contact number!")
      .typeError("Please enter number only!"),
    email: Yup.string().required("Please enter email!").email("Invalid email"),
    date: Yup.date()
      .required("Please select date of event!")
      .min(today, "min date")
      .typeError("Please select date of event!"),
    number_of_guests: Yup.number("Please enter number only!")
      .required("Please enter number of guest!")
      .typeError("Please enter number only!"),
    delivery_type: Yup.string().nullable().required("Please choose!"),
    delivery_address: Yup.string().required(
      "Please enter your delivery address!"
    ),
  });

  const formOptions = { resolver: yupResolver(HomeInquirySchema) };
  const {
    register,
    values,
    touched,
    control,
    handleBlur,
    handleSubmit,
    formState,
    clearErrors,
    setError,
    setValue,
    reset,
  } = useForm(formOptions);
  const { errors } = formState;

  const saveInquiry = async (formdata) => {
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    if (token === null || token === "") {
      setError("captcha", { type: "focus", message: "Please verify captcha!" });
      return;
    } else {
      setErrorMsg("");
    }
    if (formdata) {
      formdata.date = moment(formdata?.date).format("YYYY-MM-DD");
      formdata.delivery_type = +formdata?.delivery_type;
      setProccessing(true);

      try {
        const HomeeventResponse = await axios({
          method: "post",
          url: `${API_URL}event-enquiry`,
          data: formdata,
        });
        if (HomeeventResponse?.data.status) {
          setSuccessMsg(HomeeventResponse.data.message);
          setErrorMsg("");
          reset();
        } else {
          setSuccessMsg("");
          setErrorMsg(HomeeventResponse.data.message);
        }
      } catch (error) {
        setErrorMsg(error);
        setProccessing(false);
      }

      setProccessing(false);
    }

    // Rest of your code

    // Example code to check the updated formdata
    // console.log(formdata);
  };

  const servicesList = [
    {
      name: "Chefs",
      value: 1,
    },
    {
      name: "Waiting staff",
      value: 2,
    },
    {
      name: "Crockery and cutlery",
      value: 3,
    },
    {
      name: "Glassware",
      value: 4,
    },
    {
      name: "Bar Glassware",
      value: 5,
    },
    {
      name: "Tandoor onsite",
      value: 6,
    },
    {
      name: "Table Linen",
      value: 7,
    },
  ];

  return (
    <>
      <div className="flex flex-wrap ">
        <form onSubmit={handleSubmit(saveInquiry)} className="m-auto container">
          {error_msg !== "" && typeof error_msg !== "undefined" ? (
            <p className="text-red-600 text-md pt-5 mb-0 font-Nunito_SemiBold text-center">
              {error_msg}
            </p>
          ) : null}
          <div className="w-full flex flex-wrap">
            <div className="w-full md:w-6/12 pr-0 md:pr-5  my-2">
              <input
                {...register("name")}
                id="name"
                name="name"
                type="text"
                placeholder="Your Name"
                className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                // onChange={(e) => {
                //   clearErrors(`name`);
                // }}
              />
              <small className="text-red-600">{errors?.name?.message}</small>
            </div>
            <div className="w-full md:w-6/12 pl-0 md:pl-5 my-2">
              <input
                {...register("phone")}
                id="phone"
                name="phone"
                type="text"
                placeholder="Contact Number  e.g +91123456"
                className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                onKeyDown={(event) => {
                  if (
                    !/^\d$/.test(event.key) && // Allow numeric values (0-9)
                    event.key !== "Backspace"
                  ) {
                    event.preventDefault();
                  }
                }}
                // onChange={(e) => {
                //   clearErrors(`phone`);
                // }}
              />
              <small className="text-red-600">{errors?.phone?.message}</small>
            </div>
            <div className="w-full md:w-6/12 pr-0 md:pr-5 my-2">
              <input
                {...register("email")}
                id="email"
                name="email"
                type="text"
                placeholder="E-mail"
                className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                // onChange={(e) => {
                //   clearErrors(`email`);
                // }}
              />
              <small className="text-red-600">{errors?.email?.message}</small>
            </div>

            <div className="w-full md:w-6/12 pl-0 md:pl-5 my-2">
              {/* <i className="fa-light fa-calendar-days absolute right-5 z-10 inset-y-1/2 -mt-[9px]"></i> */}
              <input
                type="date"
                className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                {...register(`date`)}
                id="date"
                placeholder="dd-mm-yyyy"
                // defaultValue={today}
                min={today}
              />
              <small className="text-red-600">{errors?.date?.message}</small>
            </div>

            <div className="w-full md:w-6/12 pr-0 md:pr-5 my-2">
              <input
                {...register("number_of_guests")}
                id="number_of_guests"
                name="number_of_guests"
                type="text"
                placeholder="Enter Number of Guests"
                onKeyDown={(event) => {
                  if (
                    !/^\d$/.test(event.key) && // Allow numeric values (0-9)
                    event.key !== "Backspace"
                  ) {
                    event.preventDefault();
                  }
                }}
                className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                //   onChange={(e) => {
                //     clearErrors(`number_of_guests`);
                //   }}
              />
              <small className="text-red-600">
                {errors?.number_of_guests?.message}
              </small>
            </div>

            <div className="w-full md:w-6/12 px-5 my-2">
              <label htmlFor="delivery_type" className="font-Nunito_SemiBold">
                Delivery Type
              </label>
              <div className="grid grid-cols-2 mt-3">
                <div className="">
                  <input
                    {...register("delivery_type")}
                    type="radio"
                    id="collection"
                    value={1}
                    className="mr-3 w-4 h-4"
                  />
                  <label
                    className="form-check-label font-Nunito_SemiBold"
                    htmlFor="collection"
                  >
                    Collection
                  </label>
                </div>
                <div className="">
                  <input
                    {...register("delivery_type")}
                    type="radio"
                    id="delivery"
                    value={2}
                    className="mr-3 w-4 h-4"
                  />
                  <label
                    className="form-check-label font-Nunito_SemiBold"
                    htmlFor="delivery"
                  >
                    Delivery
                  </label>
                </div>
              </div>

              {errors?.delivery_type && (
                <small className="text-red-600">
                  {errors?.delivery_type?.message}
                </small>
              )}
            </div>
          </div>

          <div className="float-left w-full md:w-6/12 pr-0 md:pr-5 mb-5 md:mb-0 my-2 relative">
            <textarea
              {...register(`delivery_address`)}
              id="delivery_address "
              type="text"
              placeholder="Delivery Address"
              className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none resize-none h-[210px]"
              //   onChange={(e) => {
              //     clearErrors(`delivery_address `);
              //   }}
            ></textarea>
            <small className="text-red-600">
              {errors?.delivery_address?.message}
            </small>
          </div>

          <div>
            <div className="block flex-col mt-4 md:flex">
              <label
                className="form-check-label ml-[20px] mr-[20px] font-Nunito_SemiBold"
                htmlFor="services"
              >
                Additional Services :-
              </label>

              <div className="grid grid-cols-2 ml-[20px] mr-[20px]">
                {servicesList?.map((servicedata, index) => {
                  return (
                    <div className="flex mt-3" key={index}>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input mr-3 w-4 h-4"
                          {...register("additional_services")}
                          type="checkbox"
                          // id="additional_services"
                          value={servicedata?.value}
                        />
                        <label
                          className="form-check-label font-Nunito_SemiBold"
                          // htmlFor="additional_services"
                        >
                          {servicedata?.name}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="float-left w-full py-3 relative overflow-hidden">
            <ReCAPTCHA
              id="captcha"
              name="captcha"
              sitekey={constants.RECAPTCHA_SITE_KEY}
              ref={captchaRef}
            />
            <small className="text-red-600">{errors?.captcha?.message}</small>
          </div>
          <div className="float-left w-full my-2 lg:my-0 md:pt-10 pt-5 py-3 pb-0 text-center">
            {proccessing && (
              <div className="absolute bg-gray-100/60 left-0 right-0 top-0 bottom-0 flex items-center justify-center">
                <img src={constants.FORM_LOADER} alt="" width={50} />
              </div>
            )}
            <button
              type="submit"
              className="md:text-base text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_SemiBold"
            >
              <i className="fa-solid fa-minus mr-2"></i> Send Home Enquiry
            </button>
          </div>
          <div className="clear-both"></div>
          {success_msg !== "" && typeof success_msg !== "undefined" ? (
            <p className="text-green-600 text-lg pt-5 mb-0 font-Nunito_SemiBold text-center">
              {success_msg}
            </p>
          ) : null}
        </form>
      </div>
      <div className="clear-both"></div>
    </>
  );
};
export default HomeEventInquiry;
