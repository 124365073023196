import constants from "helpers/constants";
import { Link, useNavigate } from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate();

    return (
        <>

            <section className="lg:min-h-[calc(100vh-80px-64px)] py-16 md:py-24 w-full float-left font-Averta text-center md:flex md:items-center">
                <div className="lg:container mx-auto px-5">
                    <div className='max-w-lg m-auto'>
                        <div>
                            <h2 className='text-blue font-Averta_Bold text-3xl leading-10 pb-5'>Resrouce Not Found</h2>
                            <div className='w-full float-left'>
                                <img src={constants.PAGE_NOT_FOUND_IMG} alt="404" />
                                <div className="clear-both"></div>
                                <button onClick={() => navigate(-1)} type="button" className="inline-block m-auto mt-16 mb-10 bg-gray-200 hover:bg-burgandy py-4 px-16 rounded-full text-blue hover:text-white mr-3 font-bold text-sm">Go Back</button>
                                <Link className="inline-block m-auto mt-16 mb-10 bg-blue hover:bg-burgandy py-4 px-16 bg-gray-200 rounded-full text-blue font-bold hover:text-white text-sm" to='/'>Home Page</Link>
                                <div className="clear-both"></div>
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </section>
        </>
    );
}

export default PageNotFound;