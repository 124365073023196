import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  TestPage,
  Home,
  Venue,
  AboutUs,
  EventMenu,
  Gallery,
  NewContactUs,
  // ContactUs,
  Blog,
  BlogDetail,
  Review,
  WeddingInspirations,
  Services,
  Takeaway,
  Help,
  DocumentPage,
} from "MdlPages";
import { PageNotFound } from "ErrorPages";

import authContext from "contexts/auth/authContext";
import TakewayInquiry from "modules/pages/TakewayInquiry";
import BookCall from "modules/pages/BookCall";
import Tastings from "modules/pages/Tastings";

/** Middleware to check if user is not logged in */
const PrivateRoute = ({ children }) => {
  const cntxAuth = useContext(authContext);
  return cntxAuth.isLogged ? children : <Navigate to="/login" />;
};

/** Middleware to check if user is logged in */
const LoggedRoute = ({ children }) => {
  const cntxAuth = useContext(authContext);
  return cntxAuth.isLogged ? <Navigate to="/login" /> : children;
};

const Router = () => {
  return (
    <>
      <Routes basename="/">
        <Route exact path="/" element={<Home />} />
        <Route exact path="/venues" element={<Venue />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/our-menus" element={<EventMenu />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/contact-us" element={<NewContactUs />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/blog-detail/:url" element={<BlogDetail />} />
        <Route exact path="/reviews" element={<Review />} />
        <Route exact path="/weddings" element={<WeddingInspirations />} />
        {/* <Route exact path="/services" element={<Services />} /> */}
        <Route exact path="/takeaway" element={<Takeaway />} />
        <Route exact path="/book-a-call" element={<BookCall />} />
        <Route exact path="/takeaways-inquiry" element={<TakewayInquiry />} />
        <Route exact path="/help" element={<Help />} />
        <Route exact path="/docs" element={<DocumentPage />} />
        <Route exact path="/tastings" element={<Tastings />} />

        {/* ERROR ROUTES */}
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
        {/* ERROR ROUTES */}
      </Routes>
    </>
  );
};

export default Router;
