import React, { useEffect, useState } from "react";
import { Breadcrumb } from "flowbite-react";
import { Link } from "react-router-dom";
import { Carousel } from "flowbite-react";
import axios from "axios";
import parse from "html-react-parser";
import constants from "helpers/constants";

const Takeaway = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [menus, setMenus] = useState([]);
  const [menu_id, setMenuId] = useState(0);
  const [content, setContent] = useState("");
  const [banner, setBanner] = useState("");
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    fetchMenus();
    fetchBanner();
    fetchGalleries();
  }, []);

  const fetchBanner = async () => {
    let url = `${API_URL}get-banner/${19}`;
    const cat = await axios
      .get(url)
      .then(function (response) {
        setBanner(response.data?.data?.banner_data);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };

  const fetchMenus = async () => {
    let url = `${API_URL}get-takeaway-menu-title`;
    const cat = await axios
      .get(url)
      .then(function (response) {
        setMenus(response.data.data);
        setMenuId(Object.keys(response.data.data)[0]);
        fetchDescription(Object.keys(response.data.data)[0]);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };

  const fetchDescription = async (id) => {
    let url = `${API_URL}get-takeaway-menu-by-id?id=${id}`;
    const cat = await axios
      .get(url)
      .then(function (response) {
        setContent(response.data.data);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };

  const fetchGalleries = async () => {
    let url = `${API_URL}get-takeaway-menu`;
    const gal = await axios
      .get(url)
      .then(function (response) {
        setBanners(response.data.data.banner_data);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };

  return (
    <>
      <div className="bg-white lg:h-[600px] h-[300px] relative">
        <img
          className="h-full w-full object-cover float-left"
          src={
            banner?.image_url !== ""
              ? banner?.image_url
              : `${process.env.REACT_APP_FRONT_URL}assets/images/takeaway_banner.jpg`
          }
          alt=""
        />
        <div className="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-white flex items-center font-Nunito_SemiBold">
          <div className="container m-auto px-4">
            <Breadcrumb aria-label="Default breadcrumb example">
              <Link
                to="/"
                className="uppercase font-Nunito_SemiBold text-black hover:text-burgandy"
              >
                Home
              </Link>
              <Breadcrumb.Item className="uppercase">Takeaways</Breadcrumb.Item>
            </Breadcrumb>
            {banner !== null && typeof banner !== "undefined" ? (
              <>
                {parse(
                  banner.short_description ? banner.short_description : ""
                )}
              </>
            ) : (
              <>
                <h1 className="text-[24px] md:text-[36px] leading-[32px] md:leading-[46px] font-Nunito_Bold text-burgandy py-4">
                  Giving Your
                  <br />
                  Hunger a New Option
                </h1>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="clear-both"></div>
      {banners !== "" &&
        banners.length > 0 &&
        typeof banners !== "undefined" && (
          <div className="bg-white float-left w-full md:py-24 py-12">
            <div className="container m-auto px-4 relative">
              <img
                className="w-[75px] float-left absolute -top-[80px] right-1 sm:block hidden"
                src={`${process.env.REACT_APP_FRONT_URL}assets/images/wedding_shap2.svg`}
                alt=""
              />
              <div className="float-left w-full">
                <h2 className="lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold">
                  Enjoy our delicious food in the comfort of your own home
                </h2>
                <span className="w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2"></span>
                <div className="clear-both"></div>
              </div>
              <div className="float-left w-full">
                <Carousel
                  slideInterval={4000}
                  indicators={false}
                  leftControl={
                    <i className="fa-solid fa-arrow-left-from-line w-[36px] h-[36px] leading-[36px] bg-white hover:bg-gray-200 transition-all text-lg"></i>
                  }
                  rightControl={
                    <i className="fa-solid fa-arrow-right-from-line w-[36px] h-[36px] leading-[36px] bg-white hover:bg-gray-200 transition-all text-lg"></i>
                  }
                >
                  {banners.map((gallery, index) => (
                    <img
                      key={index}
                      className="w-full float-left max-h-[600px] object-cover relative"
                      src={
                        gallery?.image_url
                          ? gallery?.image_url
                          : constants.NO_IMAGE
                      }
                      alt=""
                    />
                  ))}
                </Carousel>
              </div>
              <div className="clear-both"></div>
            </div>
          </div>
        )}

      <div className="clear-both"></div>
      <div className="bg-white float-left w-full md:py-24 md:pb-5 py-12 relative">
        <img
          className="w-[120px] float-left absolute inset-y-2/4 left-0 sm:block hidden"
          src={`${process.env.REACT_APP_FRONT_URL}assets/images/wedding_shap1.svg`}
          alt=""
        />
        <div className="container m-auto px-4 relative">
          <img
            className="w-[70px] float-left absolute -top-[50px] right-1"
            src={`${process.env.REACT_APP_FRONT_URL}assets/images/takeaway_shap1.svg`}
            alt=""
          />
          <div className="float-left w-full pb-3">
            <h2 className="lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold">
              Stress Free Catering
            </h2>
            <span className="w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2"></span>
            <div className="clear-both"></div>
          </div>
          <div className="float-left w-full">
            <ul className="flex sm:flex-row flex-wrap md:gap-10 gap-6 font-Nunito_Regular md:text-base text-sm py-5 mb-5">
              {menus !== "" && typeof menus !== "undefined" && (
                <>
                  {Object.keys(menus).map((index) => (
                    <li key={index}>
                      <Link
                        onClick={() => {
                          setMenuId(index);
                          fetchDescription(index);
                        }}
                        className={`${
                          menu_id === index
                            ? "text-burgandy border-b border-burgandy pb-1"
                            : "text-[#696969]"
                        } hover:text-burgandy border-b border-transparent hover:border-burgandy pb-1`}
                      >
                        {menus[index]}
                      </Link>
                    </li>
                  ))}
                </>
              )}
            </ul>
            <div className="float-left w-full">
              <div className="block">
                {content !== "" &&
                  content !== null &&
                  content?.menu_pdf_url !== null && (
                    <>
                      <h3 className="lg:text-xl text-base text-black font-Nunito_SemiBold py-3">
                        For More Details to Download Full Menu PDF
                      </h3>
                      <a
                        href={
                          content?.menu_pdf_url ? content.menu_pdf_url : "#"
                        }
                        target="_blank"
                        download
                        className="transition-colors text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_SemiBold mb-10 leading-8"
                      >
                        <img
                          className="w-[20px] float-left mr-2 mt-1"
                          src={`${process.env.REACT_APP_FRONT_URL}assets/images/pdf-icon.svg`}
                          alt=""
                        />{" "}
                        Download Menu
                      </a>
                    </>
                  )}
                <div className="clear-both"></div>
                {parse(content?.description ? content.description : "")}
                <div className="clear-both"></div>
              </div>
            </div>
          </div>
          <div className="clear-both"></div>
          <Link
            to={`/contact-us/?enquiry=Food Order`}
            className="transition-colors text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_SemiBold "
          >
            <i className="fa-solid fa-minus mr-2"></i> Home Event Enquiry
          </Link>
        </div>
      </div>
    </>
  );
};

export default Takeaway;
