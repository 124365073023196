import React, { useEffect, useState, useTransition, useRef } from "react";
import { Breadcrumb, Label, Radio } from "flowbite-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import {
  ContactUsSchema,
  GeneralEnquirySchema,
} from "modules/validations/Schema";
import constants from "../../../helpers/constants";
import WeddingInquiry from "../forms/WeddingInquiry";
import ReCAPTCHA from "react-google-recaptcha";
import parse from "html-react-parser";
import Loader from "./Loader";

const GeneralInquiry = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [startDate, setStartDate] = useState(new Date());
  const [value, onChange] = useState("10:00");
  const [success_msg, setSuccessMsg] = useState("");
  const [selectedEnquiry, setSelectedEnquiry] = useState("general");
  const [fetchingFields, setFetchingFields] = useState(false);

  const [error_msg, setErrorMsg] = useState("");
  const { t } = useTransition();
  const [content, setContent] = useState("");
  const [categories, setCategories] = useState("");
  const [titles, setTitles] = useState("");
  const [proccessing, setProccessing] = useState(false);
  const [form_view, setFormView] = useState(0);
  const [banner, setBanner] = useState("");
  const [captchatoken, setCaptchatoken] = useState("");
  const [recaptchakey, setreCaptchakey] = useState(false);
  const captchaRef = useRef(null);

  const queryParameters = new URLSearchParams(window.location.search);
  const enquiry = queryParameters.get("enquiry");

  useEffect(() => {
    setreCaptchakey(true);

    fetchContent();
    fetchFields();
    fetchBanner();
    return () => {
      setreCaptchakey(false);
    };
  }, []);

  useEffect(() => {
    if (captchatoken) {
      setErrors({
        captcha: "",
      });
    }
  }, [captchatoken]);

  const fetchBanner = async () => {
    let url = `${API_URL}get-banner/${22}`;
    const cat = await axios
      .get(url)
      .then(function (response) {
        setBanner(response.data?.data?.banner_data);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };

  const fetchContent = async () => {
    let url = `${API_URL}get-header-footer-data`;
    const content = await axios
      .get(url)
      .then(function (response) {
        setContent(response.data.data.footer);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };
  const fetchFields = async () => {
    setFetchingFields(true); // Update state to indicate fetching has started
    let url = `${API_URL}get-contactus`;
    try {
      const response = await axios.get(url);
      setCategories(response.data.data.categories);
      setTitles(response.data.data.title);
      setFetchingFields(false); // Update state to indicate fetching has ended
    } catch (error) {
      console.log("error : ", error);
      setFetchingFields(false); // Update state in case of error
    }
  };

  const initialValues = {
    // category_id: "",
    title: "",
    name: "",
    phone: "",
    email: "",
    message: "",
    is_mailing_list: "",
    // captcha: "",
  };
  const {
    errors,
    values,
    touched,
    isMulti,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    setErrors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: GeneralEnquirySchema,
    validateOnChange: true, // Add this line to trigger validation on onChange
    errors: "",
    onSubmit: async (values, action) => {
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      if (token === null || token === "") {
        setErrors({ captcha: "Please verify captcha!" });
        return;
      } else {
        setErrorMsg("");
      }
      setProccessing(true);
      let url = `${API_URL}general-enquiry`;
      const response = await axios
        .post(url, values)
        .then(function (response) {
          if (response.data.status) {
            setSuccessMsg(response.data.message);
            setErrorMsg("");
            action.resetForm();

            setTimeout(() => {
              setSuccessMsg("");
            }, 5000);
          } else {
            setSuccessMsg("");
            setErrorMsg(response.data.message);
          }
          setProccessing(false);
        })
        .catch(function (error) {
          console.log("error : ", error);
          setProccessing(false);
        });
    },
  });

  return (
    <>
      {" "}
      <div className="-mx-5 relative">
        {fetchingFields ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="float-left w-full md:w-6/12 px-5">
              <div className="float-left w-full my-2">
                <select
                  id="title"
                  name="title"
                  className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none cursor-pointer"
                  // onChange={handleChange}
                  onChange={(e) => {
                    setFieldValue("title", e.target.value);
                    setFieldTouched("title", true, false);
                  }}
                  onBlur={handleBlur}
                  value={values.title}
                >
                  <option value="" className="text-gray-400">
                    Title
                  </option>
                  {titles !== "" &&
                    typeof titles !== "undefined" &&
                    Object.entries(titles).map(([id, value], index) => (
                      <option key={index} value={id}>
                        {value}
                      </option>
                    ))}
                </select>
                {errors.title && touched.title ? (
                  <small className="text-red-600">{errors.title}</small>
                ) : null}
              </div>
              <div className="float-left w-full my-2">
                <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Your Name"
                  className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                  // onChange={handleChange}
                  onChange={(e) => {
                    const trimmedValue = e.target.value.replace(/^\s+/, "");
                    setFieldValue("name", trimmedValue);
                    setFieldTouched("name", true, false);
                  }}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {errors.name && touched.name ? (
                  <small className="text-red-600">{errors.name}</small>
                ) : null}
              </div>
              <div className="float-left w-full my-2">
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  placeholder="Contact Number e.g +91123456"
                  className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                  // onChange={handleChange}
                  onChange={(e) => {
                    const inputValue = e.target.value.trim();

                    // Check if the input is a valid phone number format (digits with optional leading +)
                    if (/^\+?\d*$/.test(inputValue)) {
                      setFieldValue("phone", inputValue);
                      setFieldTouched("phone", true, false);
                    } else {
                      // Display an error if the input is not in the correct format
                      setFieldValue("phone", "");
                      setFieldTouched("phone", true, false);
                    }
                  }}
                  onBlur={handleBlur}
                  value={values.phone}
                />
                {errors.phone && touched.phone ? (
                  <small className="text-red-600">{errors.phone}</small>
                ) : null}
              </div>
              <div className="float-left w-full my-2">
                <input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="E-mail"
                  className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                  // onChange={handleChange}
                  onChange={(e) => {
                    const trimmedValue = e.target.value.trim();

                    setFieldValue("email", trimmedValue);
                    setFieldTouched("email", true, false);
                  }}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email ? (
                  <small className="text-red-600">{errors.email}</small>
                ) : null}
              </div>
              <div className="float-left w-full my-2">
                <fieldset
                  className="flex py-4 text-base text-black font-Nunito_Regular justify-between items-start flex-row gap-4 pb-0"
                  id="radio1"
                >
                  <div className="flex items-start gap-5">
                    <legend className="font-Nunito_SemiBold">
                      Would you like to be added to our mailing list?
                    </legend>
                  </div>
                  <div className="flex items-end gap-5">
                    <div className="flex items-center gap-2">
                      <Radio
                        id="is_mailing_list"
                        name="is_mailing_list"
                        value="1"
                        checked={values.is_mailing_list === "1"}
                        onChange={() => {
                          setFieldValue("is_mailing_list", "1");
                        }}
                        onBlur={handleBlur}
                        // checked="checked"
                      />
                      <Label htmlFor="is_mailing_list-yes">Yes</Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <Radio
                        id="is_mailing_listNo"
                        name="is_mailing_list"
                        value="0"
                        checked={values.is_mailing_list === "0"}
                        onChange={() => setFieldValue("is_mailing_list", "0")}
                        onBlur={handleBlur}
                      />
                      <Label htmlFor="is_mailing_listNo">No</Label>
                    </div>
                  </div>
                </fieldset>
                {errors.is_mailing_list && touched.is_mailing_list ? (
                  <small className="text-red-600">
                    {errors.is_mailing_list}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="float-right w-full md:w-6/12 px-5 relative">
              <img
                class="w-[140px] float-left absolute -top-10 -right-10 xl:block hidden"
                src={`${process.env.REACT_APP_FRONT_URL}assets/images/aboutimg_shap-01.svg`}
                alt=""
              />
              <div className="float-left w-full my-2 relative">
                <textarea
                  id="message"
                  name="message"
                  type="text"
                  placeholder="Please elaborate further on the details of the inquiry."
                  className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none resize-none h-[324px]"
                  // onChange={handleChange}
                  onChange={(e) => {
                    const trimmedValue = e.target.value.replace(/^\s+/, "");
                    setFieldValue("message", trimmedValue);
                    setFieldTouched("message", true, false);
                  }}
                  onBlur={handleBlur}
                  value={values.message}
                ></textarea>
                {errors.message && touched.message ? (
                  <small className="text-red-600">{errors.message}</small>
                ) : null}
              </div>
            </div>
            {/* <div className="float-left w-full md:w-6/12 px-5 my-2">
            <fieldset
              className="flex py-4 text-base text-black font-Nunito_Regular justify-between items-start flex-row gap-4"
              id="radio1"
            >
              <div className="flex items-start gap-5">
                <legend className="font-Nunito_SemiBold">
                  Would you like to be added to our mailing list?
                </legend>
              </div>
              <div className="flex items-end gap-5">
                <div className="flex items-center gap-2">
                  <Radio
                    id="is_mailing_list"
                    name="is_mailing_list"
                    value="1"
                    onChange={() => setFieldValue("is_mailing_list", "1")}
                    onBlur={handleBlur}
                    // checked="checked"
                  />
                  <Label htmlFor="is_mailing_list-yes">Yes</Label>
                </div>
                <div className="flex items-center gap-2">
                  <Radio
                    id="is_mailing_listNo"
                    name="is_mailing_list"
                    value="0"
                    onChange={() => setFieldValue("is_mailing_list", "0")}
                    onBlur={handleBlur}
                  />
                  <Label htmlFor="is_mailing_listNo">No</Label>
                </div>
                {errors.is_mailing_list && touched.is_mailing_list ? (
                  <small className="text-red-600">
                    {errors.is_mailing_list}
                  </small>
                ) : null}
              </div>
            </fieldset>
          </div> */}
            <div className="float-left w-full px-5 py-3 relative overflow-hidden">
              {recaptchakey && (
                <ReCAPTCHA
                  // key={captchakey}
                  id="captcha"
                  name="captcha"
                  sitekey={constants.RECAPTCHA_SITE_KEY}
                  ref={captchaRef}
                  onChange={(e) => {
                    setCaptchatoken(e);
                  }}
                  asyncScriptOnLoad={console.log}
                  onErrored={console.log}
                />
              )}
              <small className="text-red-600">{errors.captcha}</small>
            </div>
            <div className="float-left w-full my-2 lg:my-0 md:pt-10 pt-5 py-3 pb-0 text-center">
              {proccessing && (
                <div className="absolute bg-gray-100/60 left-0 right-0 top-0 bottom-0 flex items-center justify-center">
                  <img src={constants.FORM_LOADER} alt="" width={50} />
                </div>
              )}
              <button
                type="submit"
                className="md:text-base text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_SemiBold"
              >
                <i className="fa-solid fa-minus mr-2"></i> Send Enquiry
              </button>
            </div>
          </form>
        )}

        <div className="clear-both"></div>
        {success_msg !== "" && typeof success_msg !== "undefined" ? (
          <p className="text-green-600 text-lg pt-5 mb-0 font-Nunito_SemiBold text-center">
            {success_msg}
          </p>
        ) : null}
        {error_msg !== "" && typeof error_msg !== "undefined" ? (
          <p className="text-green-600 text-lg pt-5 mb-0 font-Nunito_SemiBold text-center">
            {error_msg}
          </p>
        ) : null}
      </div>
      <div className="clear-both"></div>
    </>
  );
};

export default GeneralInquiry;
