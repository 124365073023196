import React, { useState } from "react";
import axios from "axios";
import { Breadcrumb } from "flowbite-react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Loader from "./forms/Loader";

const Help = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [banner, setBanner] = useState("");
  const [helpData, setHelpData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBanner();
    fetchHelpDetails();
  }, []);

  const fetchBanner = async () => {
    let url = `${API_URL}get-banner/${22}`;
    const cat = await axios
      .get(url)
      .then(function (response) {
        setBanner(response.data?.data?.banner_data);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };

  const fetchHelpDetails = async () => {
    let url = `${API_URL}get-documents?type_of_document=2?is_pagination=0`;

    setLoading(true);
    const response = await axios
      .get(url)
      .then((response) => {
        setLoading(false);
        if (
          response?.data?.data?.document_list?.data?.length > 0 &&
          response.data.status
        ) {
          setHelpData(response?.data?.data?.document_list?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "error");
      });
  };

  const downloadPDF = (data) => {
    axios({
      url: data.document_pdf_url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", data.document_pdf);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  return (
    <>
      <div className="bg-white lg:h-[600px] h-[300px] relative">
        <img
          className="h-full w-full object-cover float-left"
          src={
            banner?.image_url !== ""
              ? banner?.image_url
              : `${process.env.REACT_APP_FRONT_URL}assets/images/gallery_banner.jpg`
          }
          alt=""
        />
        <div className="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-white flex items-center font-Nunito_SemiBold">
          <div className="container m-auto px-4">
            <Breadcrumb aria-label="Default breadcrumb example">
              <Link
                to="/"
                className="uppercase font-Nunito_SemiBold text-black hover:text-burgandy"
              >
                Home
              </Link>
              <Breadcrumb.Item className="uppercase">Help</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 float-left w-full md:py-24 py-12">
        <div className="container m-auto px-4 relative">
          <img
            className="md:w-[235px] w-[135px] float-left absolute md:-top-[160px] -top-[80px] left-1"
            src={`${process.env.REACT_APP_FRONT_URL}assets/images/banner_shape.svg`}
            alt=""
          />
          <div className="float-left w-full text-center">
            <h2 className="lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold">
              Help
            </h2>
            <span className="w-[70px] h-[2px] block m-auto bg-burgandy mb-8 mt-2"></span>
            <h3 className="lg:text-xl  text-base text-black  text-start ml-40 font-Nunito_SemiBold py-3">
              <span className="doc-icon-note"></span>
              {""} Please click on the document name to download.
            </h3>
            <p className="md:w-9/12 md:m-auto md:leading-6 md:text-base text-sm leading-5 font-Nunito_Regular text-light_blk pb-5">
              {loading ? (
                <Loader />
              ) : helpData.length == 0 ? (
                <p>No Data Found</p>
              ) : (
                <ul className="grid grid-cols-1 md:grid-cols-2 list-disc gap-4 p-6">
                  {helpData.map((data, index) =>
                    data.document_pdf.split(".").pop() == "doc" ||
                    data.document_pdf.split(".").pop() == "docx" ? (
                      <li key={index} className="w-fit cursor-pointer">
                        <a
                          download={data?.document_pdf}
                          href={data.document_pdf_url}
                          target="_blank"
                        >
                          {data.title}
                        </a>
                      </li>
                    ) : (
                      <li
                        key={index}
                        className="w-fit cursor-pointer"
                        onClick={() => downloadPDF(data)}
                      >
                        {data.title}
                      </li>
                    )
                  )}
                </ul>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
