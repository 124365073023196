import React, { useEffect, useState } from "react";
import { Breadcrumb } from "flowbite-react";
import { Link } from "react-router-dom";
import { Carousel } from "flowbite-react";
import { GalleryLoader } from "components/interfaces/SkeletonLoader";
import axios from "axios";
import Fancybox from "modules/fragments/FancyBox";
import constants from "helpers/constants";
import parse from "html-react-parser";

const WeddingInspirations = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [galleries, setGalleries] = useState([]);
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState("");

  useEffect(() => {
    fetchGalleries();
    fetchBanner();
  }, []);

  const fetchBanner = async () => {
    let url = `${API_URL}get-banner/${16}`;
    const cat = await axios
      .get(url)
      .then(function (response) {
        setBanner(response.data?.data?.banner_data);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };

  const fetchGalleries = async () => {
    setLoading(true);
    let url = `${API_URL}get-wedding-inspiration-data`;
    const gal = await axios
      .get(url)
      .then(function (response) {
        setGalleries(response.data.data.gallery_data);
        setBanners(response.data.data.banner_data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log("error : ", error);
        setLoading(false);
      });
  };
  return (
    <>
      <div className="bg-white lg:h-[600px] h-[300px] relative">
        <img
          className="h-full w-full object-cover float-left"
          src={
            banner?.image_url !== ""
              ? banner?.image_url
              : `${process.env.REACT_APP_FRONT_URL}assets/images/wedding_banner.jpg`
          }
          alt=""
        />
        <div className="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-white flex items-center font-Nunito_SemiBold">
          <div className="container m-auto px-4">
            <Breadcrumb aria-label="Default breadcrumb example">
              <Link
                to="/"
                className="uppercase font-Nunito_SemiBold text-black hover:text-burgandy"
              >
                Home
              </Link>
              <Breadcrumb.Item className="uppercase">Weddings</Breadcrumb.Item>
            </Breadcrumb>
            {banner !== null && typeof banner !== "undefined" ? (
              <>
                {parse(
                  banner.short_description ? banner.short_description : ""
                )}
              </>
            ) : (
              <>
                <h1 className="text-[24px] md:text-[36px] leading-[32px] md:leading-[46px] font-Nunito_Bold text-burgandy py-4">
                  Together is a<br />
                  Beautiful Place to be
                </h1>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="clear-both"></div>
      {/* <div className='bg-gray-100 float-left w-full md:py-24 md:pb-10 py-12'>
                <div className='container m-auto px-4 relative'>
                    <img className='w-[95px] float-left absolute -top-[10px] right-1' src={`${process.env.REACT_APP_FRONT_URL}assets/images/wedding_shap1.svg`} alt="" />
                    <img className='w-[100px] float-left absolute -bottom-[10px] md:-left-10 -left-5' src={`${process.env.REACT_APP_FRONT_URL}assets/images/aboutimg_shap-01.svg`} alt="" />
                    <div className='float-left w-full pb-3'>
                        <h2 className='lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold'>Preeti Catering's Services</h2>
                        <span className='w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2'></span>
                        <div className="clear-both"></div>
                    </div>
                    <div className='float-left w-full'>
                        <div className='grid lg:grid-cols-3 lg:gap-10 md:grid-cols-2 md:gap-6 grid-cols-1 gap-4'>
                            <div className='float-left w-full relative'>
                                <Link to={`/v1/our-menus`} className=" flex items-center justify-center gap-5 flex-col text-black bg-white py-10 rounded-2xl hover:text-burgandy">
                                    <img className='h-[70px] float-left' src={`${process.env.REACT_APP_FRONT_URL}assets/images/wedding_icon1.svg`} alt="" />
                                    <h4 className="md:text-xl text-base font-Nunito_SemiBold ">Brownies & Cheesecake</h4>
                                </Link>
                            </div>
                            <div className='float-left w-full relative'>
                                <Link to={`/v1/services`} className=" flex items-center justify-center gap-5 flex-col text-black bg-white py-10 rounded-2xl hover:text-burgandy">
                                    <img className='h-[70px] float-left' src={`${process.env.REACT_APP_FRONT_URL}assets/images/wedding_icon2.svg`} alt="" />
                                    <h4 className="md:text-xl text-base font-Nunito_SemiBold">Corporate & Catering</h4>
                                </Link>
                            </div>
                            <div className='float-left w-full relative'>
                                <Link to={`/v1/venues`} className=" flex items-center justify-center gap-5 flex-col text-black bg-white py-10 rounded-2xl hover:text-burgandy">
                                    <img className='h-[70px] float-left' src={`${process.env.REACT_APP_FRONT_URL}assets/images/wedding_icon3.svg`} alt="" />
                                    <h4 className="md:text-xl text-base font-Nunito_SemiBold">Banquet & Venues</h4>
                                </Link>
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div> */}

      <div className="clear-both"></div>
      {banners !== "" &&
        banners.length > 0 &&
        typeof banners !== "undefined" && (
          <div className="bg-white float-left w-full md:py-24 py-12">
            <div className="container m-auto px-4 relative">
              <img
                className="w-[75px] float-left absolute -top-[80px] right-1 sm:block hidden"
                src={`${process.env.REACT_APP_FRONT_URL}assets/images/wedding_shap2.svg`}
                alt=""
              />
              {/* <div className="float-left w-full">
                <h2 className="lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold">
                  Together is a Beautiful Place to be
                </h2>
                <span className="w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2"></span>
                <div className="clear-both"></div>
              </div> */}
              <div className="float-left w-full">
                <Carousel
                  slideInterval={4000}
                  indicators={false}
                  leftControl={
                    <i className="fa-solid fa-arrow-left-from-line w-[36px] h-[36px] leading-[36px] bg-white hover:bg-gray-200 transition-all text-lg"></i>
                  }
                  rightControl={
                    <i className="fa-solid fa-arrow-right-from-line w-[36px] h-[36px] leading-[36px] bg-white hover:bg-gray-200 transition-all text-lg"></i>
                  }
                >
                  {banners.map((gallery, index) => (
                    <img
                      key={index}
                      className="w-full float-left max-h-[600px] object-cover relative"
                      src={
                        gallery?.image_url
                          ? gallery?.image_url
                          : constants.NO_IMAGE
                      }
                      alt=""
                    />
                  ))}
                </Carousel>
              </div>
              <div className="clear-both"></div>
            </div>
          </div>
        )}

      <div className="clear-both"></div>
      <div className="bg-gray-100 float-left w-full md:py-24 py-12">
        <div className="container m-auto px-4 relative">
          <img
            className="md:w-[235px] w-[135px] float-left absolute md:-top-[160px] -top-[80px] left-1"
            src={`${process.env.REACT_APP_FRONT_URL}assets/images/banner_shape.svg`}
            alt=""
          />
          <div className="float-left w-full text-center">
            <h2 className="lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold">
              The Complete Package
            </h2>
            <span className="w-[70px] h-[2px] block m-auto bg-burgandy mb-8 mt-2"></span>
            <div className="clear-both"></div>
            <p className="md:w-9/12 md:m-auto md:leading-6 md:text-base text-sm leading-5 font-Nunito_Regular text-light_blk pb-5">
              Weddings are such special, important events where memories are
              made to last a lifetime. We aim to take the stress away from our
              clients so that they can enjoy the process of planning their
              wedding with us. We use our many years of experience to guide and
              support our clients through this very exciting time.
            </p>
            <p className="md:w-9/12 md:m-auto md:leading-6 md:text-base text-sm leading-5 font-Nunito_Regular text-light_blk pb-5">
              From the initial consultation through to the food tasting,
              planning meeting and event day itself we will be with you every
              step of the way.
            </p>
            <Link
              to={`/contact-us?enquiry=Wedding Enquiry`}
              className="transition-colors text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_SemiBold "
            >
              <i className="fa-solid fa-minus mr-2"></i> Contact us for more
              information{" "}
            </Link>
          </div>
          <div className="clear-both"></div>
        </div>
      </div>

      <div className="clear-both"></div>
      <div className="bg-white float-left w-full md:py-24 md:pb-10 py-12">
        <div className="container m-auto px-4 relative">
          <img
            className="md:w-[150px] w-[90px] float-left absolute md:-top-[200px] -top-[100px] right-1 sm:block hidden"
            src={`${process.env.REACT_APP_FRONT_URL}assets/images/about_page_shap3.svg`}
            alt=""
          />
          <div className="-mx-3">
            <div className="float-left w-full px-3">
              <h2 className="lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold">
                {/* The Complete Gallery That You'll Ever Find */}
                The perfect menu for every occasion
              </h2>
              <span className="w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2"></span>
              <div className="clear-both"></div>
            </div>
            <div className="clear-both"></div>
          </div>
          <div className="float-left w-full">
            <div className="grid lg:grid-cols-4 lg:gap-6 md:grid-cols-3 md:gap-4 sm:grid-cols-2 sm:gap-4 grid-cols-2 gap-4">
              {loading ? (
                <>
                  <GalleryLoader count={4} />
                </>
              ) : (
                <>
                  {galleries !== "" &&
                  galleries.length > 0 &&
                  typeof galleries !== "undefined" ? (
                    <>
                      {galleries.map((gallery, index) => (
                        <div key={index} className="float-left w-full relative">
                          <Fancybox options={{ infinite: false }}>
                            <button
                              data-fancybox
                              data-caption={
                                gallery?.short_description
                                  ? gallery?.short_description
                                  : ""
                              }
                              data-src={
                                gallery?.image_url
                                  ? gallery?.image_url
                                  : constants.NO_IMAGE
                              }
                              disabled
                              className="absolute top-0 left-0 bottom-0 right-0 z-10 cursor-pointer"
                            >
                              <img
                                className="h-full hidden w-full object-cover float-left"
                                src={
                                  gallery?.image_url
                                    ? gallery?.image_url
                                    : constants.NO_IMAGE
                                }
                                alt=""
                              />
                            </button>
                          </Fancybox>
                          <img
                            className="h-full w-full object-cover float-left"
                            src={
                              gallery?.image_url
                                ? gallery?.image_url
                                : constants.NO_IMAGE
                            }
                            alt=""
                          />
                          <div className="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-t from-black/70 flex items-start justify-end flex-col pb-4 pl-4 text-white font-Nunito_Regular">
                            <p className="text-sm sm:pb-1">
                              <i className="fa-solid fa-minus mr-2"></i>{" "}
                              {gallery?.category_name}
                            </p>
                            <h4 className="text-base sm:block hidden">
                              {gallery?.title}
                            </h4>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <p className="text-center text-lg font-Nunito_SemiBold text-black">
                        No image found
                      </p>
                    </>
                  )}
                </>
              )}
              <div className="clear-both"></div>
            </div>
            <div className="float-left w-full text-center pt-5">
              <Link
                to={`/gallery`}
                className="transition-colors text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_SemiBold "
              >
                <i className="fa-solid fa-minus mr-2"></i> Go Gallery
              </Link>
            </div>
          </div>
          <div className="clear-both"></div>
        </div>
      </div>
    </>
  );
};

export default WeddingInspirations;
