import React, { useEffect, useState, useTransition, useRef } from 'react';
import { Breadcrumb, Label, Radio } from 'flowbite-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useFormik } from 'formik';
import { ContactUsSchema } from 'modules/validations/Schema';
import constants from '../../helpers/constants'
import WeddingInquiry from './forms/WeddingInquiry';
import ReCAPTCHA from 'react-google-recaptcha';
import parse from 'html-react-parser';

const BookCall = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [startDate, setStartDate] = useState(new Date());
    const [value, onChange] = useState('10:00');
    const [success_msg, setSuccessMsg] = useState('');
    const [error_msg, setErrorMsg] = useState('');
    const { t } = useTransition();
    const [content, setContent] = useState('');
    const [categories, setCategories] = useState('');
    const [titles, setTitles] = useState('');
    const [proccessing, setProccessing] = useState(false);
    const [form_view, setFormView] = useState(0);
    const [banner, setBanner] = useState('');
    const captchaRef = useRef(null);

    const queryParameters = new URLSearchParams(window.location.search);
    const enquiry = queryParameters.get("enquiry");

    useEffect(() => {
        fetchContent();
        fetchFields();
        fetchBanner();
        if (enquiry == "wedding") {
            setFormView(1);
        }
    }, []);

    const fetchBanner = async () => {
        let url = `${API_URL}get-banner/${22}`;
        const cat = await axios.get(url).then(function (response) {
            setBanner(response.data?.data?.banner_data);
        }).catch(function (error) {
            console.log('error : ', error);
        });
    }

    const fetchContent = async () => {
        let url = `${API_URL}get-header-footer-data`;
        const content = await axios.get(url).then(function (response) {
            setContent(response.data.data.footer);
        }).catch(function (error) {
            console.log('error : ', error);
        });
    }
    const fetchFields = async () => {
        let url = `${API_URL}get-contactus`;
        const cat = await axios.get(url).then(function (response) {
            setCategories(response.data.data.categories);
            setTitles(response.data.data.title);
        }).catch(function (error) {
            console.log('error : ', error);
        });
    }

    const initialValues = {
        category_id: '',
        title: '',
        name: '',
        phone: '',
        email: '',
        message: '',
        is_mailing_list: '',
        captcha: '',
    };
    const { errors, values, touched, isMulti, handleChange, handleBlur, handleSubmit, setFieldValue, setErrors } = useFormik({
        initialValues: initialValues,
        validationSchema: ContactUsSchema,
        errors: '',
        onSubmit: async (values, action) => {
            const token = captchaRef.current.getValue();
            captchaRef.current.reset();
            if (token === null || token === '') {
                setErrors({ captcha: "Please verify captcha!" })
                return;
            }
            else { setErrorMsg(''); }
            setProccessing(true);
            let url = `${API_URL}post-contact-us`;
            const response = await axios.post(url, values).then(function (response) {
                if (response.data.status) {
                    setSuccessMsg(response.data.message);
                    setErrorMsg('');
                    action.resetForm();
                }
                else {
                    setSuccessMsg('');
                    setErrorMsg(response.data.message);
                }
                setProccessing(false);
            }).catch(function (error) {
                console.log('error : ', error);
                setProccessing(false);
            });
        }
    });

    console.log(content, 'content')

    return (
        <>
            <div className='bg-white lg:h-[600px] h-[300px] relative'>
                <img className='h-full w-full object-cover float-left' src={banner?.image_url !== '' ? banner?.image_url : `${process.env.REACT_APP_FRONT_URL}assets/images/gallery_banner.jpg`} alt="" />
                <div className='absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-white flex items-center font-Nunito_SemiBold'>
                    <div className='container m-auto px-4'>
                        <Breadcrumb aria-label="Default breadcrumb example">
                            <Link to="/" className='uppercase font-Nunito_SemiBold text-black hover:text-burgandy'>
                                Home
                            </Link>
                            <Breadcrumb.Item className='uppercase'>
                                Book a Call
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        {
                            banner !== null && typeof banner !== 'undefined'
                                ?
                                <>
                                    {parse(banner.short_description ? banner.short_description : '')}
                                </>
                                :
                                <>
                                    <h1 className='text-[24px] md:text-[36px] leading-[32px] md:leading-[46px] font-Nunito_Bold text-burgandy py-4'>Get in Touch<br />With us Today</h1>
                                </>
                        }
                    </div>
                </div>
            </div>

            <div className="clear-both"></div>
            <div className="clear-both"></div>
            <div className="bg-gray-100 float-left w-full md:py-24 py-12">
                <div className="container m-auto px-4 relative">
                    <img
                        className="md:w-[235px] w-[135px] float-left absolute md:-top-[160px] -top-[80px] left-1"
                        src={`${process.env.REACT_APP_FRONT_URL}assets/images/banner_shape.svg`}
                        alt=""
                    />
                    <div className="float-left w-full text-center">
                        <h2 className="lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold">
                            Book a Call
                        </h2>
                        <span className="w-[70px] h-[2px] block m-auto bg-burgandy mb-8 mt-2"></span>
                        <div className="clear-both"></div>
                        <p className="md:w-9/12 md:m-auto md:leading-6 md:text-base text-sm leading-5 font-Nunito_Regular text-light_blk pb-5">
                            We would love to have a call with you to find out more about your wedding / event plans.
                            Complete an enquiry form and we will be in touch soon or alternatively, you can book a call with a member of the team directly below
                        </p>
                        <a
                            target='_blank'
                            href={`https://calendly.com/new-enquiry-pc`}
                            className="transition-colors text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_SemiBold "
                        >
                            {/* <i className="fa-solid fa-minus mr-2"></i> Contact us for more
                            information{" "} */}
                            Link to Calendly Diary
                        </a>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>


            <div className="clear-both"></div>
            <div className='bg-white float-left w-full md:pt-24 pt-12'>
                <div className='container m-auto px-4 relative'>
                    <img class="md:w-[140px] w-[100px] float-left absolute md:-top-[135px] -top-[85px] right-2" src={`${process.env.REACT_APP_FRONT_URL}assets/images/contact_shap.svg`} alt="" />
                    <div className='float-left w-full'>
                        <h2 className='lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold'>How to Find Us</h2>
                        <span className='w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2'></span>
                        <div className="clear-both"></div>
                    </div>
                </div>
                <div className='float-left w-full relative min-h-[400px]'>
                    <iframe className='absolute left-0 top-0 right-0 bottom-0 w-full h-full' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4965.951923965643!2d-0.39804!3d51.513657!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb4747a94010153e9!2sPreeti%20Catering!5e0!3m2!1sen!2sus!4v1672825653377!5m2!1sen!2sus" />
                </div>
            </div>
        </>
    )
};

export default BookCall;