import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'flowbite-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { MenusLoader } from 'components/interfaces/SkeletonLoader';
import parse from 'html-react-parser';

const EventMenu = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [menus, setMenus] = useState([]);
    const [menu_length, setMenuLength] = useState(10);
    const [current_count, setCurrentCount] = useState(0);
    const [total_count, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [banner, setBanner] = useState('');

    useEffect(() => {
        fetchMenus();
        fetchBanner();
    }, [menu_length]);

    const fetchBanner = async () => {
        let url = `${API_URL}get-banner/${15}`;
        const cat = await axios.get(url).then(function (response) {
            setBanner(response.data?.data?.banner_data);
        }).catch(function (error) {
            console.log('error : ', error);
        });
    }

    const fetchMenus = async () => {
        setLoading(true);
        let url = `${API_URL}get-event-menu?length=${menu_length}&is_pagination=1`;
        const cat = await axios.get(url).then(function (response) {
            setMenus(response.data.data.event_menu.data);
            setCurrentCount(response.data.data.event_menu.to);
            setTotalCount(response.data.data.event_menu.total);
            setLoading(false);
        }).catch(function (error) {
            console.log('error : ', error);
            setLoading(false);
        });
    }

    return (
        <>
            <div className='bg-white lg:h-[600px] h-[300px] relative'>
                <img className='h-full w-full object-cover float-left' src={banner?.image_url !== '' ? banner?.image_url : `${process.env.REACT_APP_FRONT_URL}assets/images/event_banner.jpg`} alt="" />
                <div className='absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-white flex items-center font-Nunito_SemiBold'>
                    <div className='container m-auto px-4'>
                        <Breadcrumb aria-label="Default breadcrumb example">
                            <Link to="/" className='uppercase font-Nunito_SemiBold text-black hover:text-burgandy'>
                                Home
                            </Link>
                            <Breadcrumb.Item className='uppercase'>
                                Our Menus
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        {
                            banner !== null && typeof banner !== 'undefined'
                                ?
                                <>
                                    {parse(banner.short_description ? banner.short_description : '')}
                                </>
                                :
                                <>
                                    <h1 className='text-[24px] md:text-[36px] leading-[32px] md:leading-[46px] font-Nunito_Bold text-burgandy py-4'>Celebrating Food &<br />Special Days</h1>
                                </>
                        }

                    </div>
                </div>
            </div>

            <div className="clear-both"></div>
            <div className='bg-white float-left w-full md:py-24 py-12'>
                <div className='container m-auto px-4 relative'>
                    <img className='w-[160px] float-left absolute -top-[80px] right-1 md:block hidden' src={`${process.env.REACT_APP_FRONT_URL}assets/images/event_menu_shap1.svg`} alt="" />
                    <img className='w-[60px] float-left absolute md:-bottom-[60px] -bottom-[40px] right-1' src={`${process.env.REACT_APP_FRONT_URL}assets/images/event_menu_shap2.svg`} alt="" />
                    <div className='-mx-3'>
                        <div className='float-left w-full px-3'>
                            <h2 className='lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold'>Browse our extensive and inspiring menus</h2>
                            <span className='w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2'></span>
                            <div className="clear-both"></div>
                        </div>
                        <div className='float-left w-full'>
                            <ul className='float-left w-full'>
                                {
                                    loading
                                        ?
                                        <>
                                            <MenusLoader count={5} />
                                        </>
                                        :
                                        <>
                                            {
                                                menus !== '' && menus.length > 0 && typeof menus !== 'undefined'
                                                    ?
                                                    <>
                                                        {
                                                            menus.map((menu, index) =>
                                                                <li key={index} className='float-left lg:w-6/12 px-3 my-6 w-full'>
                                                                    <div className='relative sm:pl-[220px] pl-[170px] border-b border-gray-200 h-[120px] overflow-hidden w-full flex justify-center flex-col'>
                                                                        <div className='absolute left-0 sm:w-[200px] h-[120px] w-[150px] overflow-hidden'><img className='float-left w-full h-full object-cover' src={menu?.image_url ? menu?.image_url : `${process.env.REACT_APP_FRONT_URL}assets/images/event_menu1.jpg`} alt="" /></div>
                                                                        <h5 className='text-black sm:text-xl text-lg font-Nunito_SemiBold pb-3 pt-3'>{menu?.title}</h5>
                                                                        <a className='text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_Bold mb-3' target="_blank" href={menu?.menu_pdf_url ? menu?.menu_pdf_url : ''} download><i className="fa-solid fa-minus"></i> Download</a>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <p className='text-center text-lg font-Nunito_SemiBold text-black'>No menu found</p>
                                                    </>
                                            }
                                        </>
                                }

                            </ul>
                        </div>
                        <div className="clear-both"></div>
                        <div className='float-left w-full text-center pt-5'>
                            {
                                current_count < total_count
                                &&
                                <button onClick={() => { setMenuLength(menu_length + 4) }} className="text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_SemiBold"><i className="fa-solid fa-minus mr-2"></i> Load More</button>
                            }
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>

        </>
    )
};

export default EventMenu;