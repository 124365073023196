import React, { useEffect, useState, useRef } from 'react';
import { Breadcrumb } from 'flowbite-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import constants from 'helpers/constants';
import { Pagination } from 'components/plugins';
import { SingleBlogLoader, BlogListLoader } from 'components/interfaces/SkeletonLoader';
import parse from 'html-react-parser';
import { useFormik } from 'formik';
import { NewsletterSchema } from 'modules/validations/Schema';
import ReCAPTCHA from 'react-google-recaptcha';

const Blog = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [blogs, setBlogs] = useState([]);
    const [length, setLength] = useState(10);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [current_count, setCurrentCount] = useState(0);
    const [total_count, setTotalCount] = useState(0);
    const [last_page, setLastPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [proccessing, setProccessing] = useState(false);
    const [success_msg, setSuccessMsg] = useState('');
    const [error_msg, setErrorMsg] = useState('');
    const [banner, setBanner] = useState('');
    const captchaRef = useRef(null);

    useEffect(() => {
        fetchBlogs();
        fetchBanner();
    }, [search, page, length]);

    const fetchBanner = async () => {
        let url = `${API_URL}get-banner/${20}`;
        const cat = await axios.get(url).then(function (response) {
            setBanner(response.data?.data?.banner_data);
        }).catch(function (error) {
            console.log('error : ', error);
        });
    }

    const fetchBlogs = async () => {
        setLoading(true);
        let url = `${API_URL}get-blogs?search=${search}&length=${length}&page=${page}&is_pagination=1`;
        const blog = await axios.get(url).then(function (response) {
            setBlogs(response.data.data.blogs.data);
            setCurrentCount(response.data.data.blogs.to);
            setTotalCount(response.data.data.blogs.total);
            setLastPage(response.data.data.blogs.last_page);
            setLoading(false);
        }).catch(function (error) {
            console.log('error : ', error);
            setLoading(false);
        });
    }

    const setScroll = () => {
        let scroll_box = document.getElementById('scroll_box');
        scroll_box.scrollIntoView({ behavior: 'smooth' });
    }

    const initialValues = {
        name: '',
        email: '',
        captcha: '',
    };
    const { errors, values, touched, isMulti, handleChange, handleBlur, handleSubmit, setErrors } = useFormik({
        initialValues: initialValues,
        validationSchema: NewsletterSchema,
        errors: '',
        onSubmit: async (values, action) => {
            const token = captchaRef.current.getValue();
            captchaRef.current.reset();
            if (token === null || token === '') {
                setErrors({ captcha: "Please verify captcha!" })
                return;
            }
            else { setErrorMsg(''); }
            setProccessing(true);
            let url = `${API_URL}newsletters/subscribe`;
            const response = await axios.post(url, values).then(function (response) {
                if (response.data.status) {
                    setSuccessMsg(response.data.message);
                    setErrorMsg('');
                    action.resetForm();
                } else {
                    setSuccessMsg('');
                    setErrorMsg(response.data.message);
                }
                setProccessing(false);
            }).catch(function (error) {
                console.log('error : ', error);
                setProccessing(false);
            });
        }
    });

    return (
        <>
            <div className='bg-white lg:h-[600px] h-[300px] relative'>
                <img className='h-full w-full object-cover float-left' src={banner?.image_url !== '' ? banner?.image_url : `${process.env.REACT_APP_FRONT_URL}assets/images/blog_banner.jpg`} alt="" />
                <div className='absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-white flex items-center font-Nunito_SemiBold'>
                    <div className='container m-auto px-4'>
                        <Breadcrumb aria-label="Default breadcrumb example">
                            <Link to="/" className='uppercase font-Nunito_SemiBold text-black hover:text-burgandy'>
                                Home
                            </Link>
                            <Breadcrumb.Item className='uppercase'>
                                Blog
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        {/* {
                            banner !== null && typeof banner !== 'undefined'
                                ?
                                <>
                                    {parse(banner.short_description ? banner.short_description : '')}
                                </>
                                :
                                <>
                                    <h1 className='text-[24px] md:text-[36px] leading-[32px] md:leading-[46px] font-Nunito_Bold text-burgandy py-4'>We Speak<br />The Language of Food</h1>
                                </>
                        } */}
                    </div>
                </div>
            </div>

            <div className="clear-both" id='scroll_box'></div>
            <div className='bg-gray-100 float-left w-full md:py-24 py-12'>
                <div className='container m-auto px-4'>
                    <div className='float-left w-full flex sm:flex-row flex-col gap-8 items-center pb-10'>
                        <div className='float-left w-full sm:w-6/12'>
                            {/* <h2 className='lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold relative'>
                                What are You Looking For ?
                                <img className='w-[40px] float-left absolute bottom-0 right-1 sm:block hidden' src={`${process.env.REACT_APP_FRONT_URL}assets/images/blog_list_shap1.svg`} alt="" />
                            </h2>
                            <span className='w-[70px] h-[2px] float-left bg-burgandy mt-2'></span>
                            <div className="clear-both"></div> */}
                        </div>
                      
                        <div className='float-left w-full sm:w-6/12'>
                          
                            <div className='float-left w-full sm:px-2 relative'>
                                <input onChange={(e) => { setSearch(e.target.value) }} type="text" placeholder="Search Blog..." className="font-Nunito_Regular text-base block w-full border-0 bg-white placeholder:text-[#000] text-black focus:outline-none rounded-full py-5 px-5 pr-14 " />
                                <i className="fa-solid fa-magnifying-glass absolute right-7 top-[24px] text-gray-400"></i>
                            </div>
                        </div>
                    </div>
                    {
                        loading
                            ?
                            <>
                                <SingleBlogLoader count={1} />
                            </>
                            :
                            <>
                                {
                                    blogs !== '' && blogs.length > 0 && typeof blogs !== 'undefined'
                                    &&
                                    <div className='float-left w-full flex lg:flex-row flex-col gap-8 items-center pt-10'>
                                        <div className='float-left lg:w-6/12 w-full lg:order-2'>
                                            <div className='float-left w-full lg:px-2 relative'>
                                                <img className='w-[100px] float-left absolute bottom-0 -right-[85px] 2xl:block hidden' src={`${process.env.REACT_APP_FRONT_URL}assets/images/wedding_shap1.svg`} alt="" />
                                                <img className='w-full float-left shadow-lg shadow-black/25 rounded-2xl relative z-10' src={blogs[0]?.image_url ? blogs[0]?.image_url : constants.NO_IMAGE} alt="" />
                                            </div>
                                        </div>
                                        <div className='float-left lg:w-6/12 w-full lg:order-1 font-Nunito_Regular'>
                                            <p className='md:leading-7 md:text-base text-sm leading-6 text-[#696969] pb-2 font-Nunito_SemiBold'><span className='text-burgandy font-Nunito_SemiBold'>{blogs[0]?.author} &nbsp; |</span> &nbsp; {blogs[0]?.date}</p>
                                            <h2 className='lg:text-[24px] text-[18px] text-black font-Nunito_SemiBold'><Link to={`/blog-detail/${blogs[0]?.url}`} className='text-black hover:text-burgandy'>{blogs[0]?.title}</Link></h2>
                                            <span className='w-[70px] h-[2px] float-left bg-burgandy mb-6 mt-2'></span>
                                            <div className="clear-both"></div>
                                            <p className='md:leading-7 md:text-base text-sm leading-6 text-light_blk pb-5'>{parse(blogs[0]?.long_desc ? blogs[0]?.long_desc : '')}</p>
                                            <Link to={`/blog-detail/${blogs[0]?.url}`} className='md:text-base text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_Bold'><i className="fa-solid fa-minus mr-2"></i> Read more</Link>
                                        </div>
                                    </div>
                                }
                            </>
                    }
                    <div className="clear-both"></div>
                </div>
            </div>

            <div className="clear-both"></div>
            <div className='bg-white float-left w-full md:pt-24 pb-10 pt-12'>
                <div className='container m-auto px-4'>
                    <div className='float-left w-full'>
                        <div className='grid lg:grid-cols-3 lg:gap-6 md:grid-cols-2 md:gap-4 grid-cols-1 gap-4'>
                            {
                                loading
                                    ?
                                    <>
                                        <BlogListLoader count={5} />
                                    </>
                                    :
                                    <>
                                        {
                                            blogs !== '' && blogs.length > 0 && typeof blogs !== 'undefined'
                                            &&
                                            blogs.map((blog, index) =>
                                                index > 0
                                                &&
                                                <div key={index} className='float-left w-full pb-8'>
                                                    <div className='float-left w-full h-[240px] rounded-xl overflow-hidden mb-5'>
                                                        <img className='w-full float-left h-full object-cover' src={blog?.image_url ? blog?.image_url : constants.NO_IMAGE} alt="" />
                                                    </div>
                                                    <div className="clear-both"></div>
                                                    <p className='text-sm leading-6 text-[#696969] pb-1 font-Nunito_SemiBold'><span className='text-burgandy font-Nunito_SemiBold'>{blog?.author ? blog?.author : 'Admin'} &nbsp; |</span> &nbsp; {blog?.date ? blog?.date : ''}</p>
                                                    <h2 className='lg:text-lg text-base text-black font-Nunito_SemiBold pb-2'><Link to={`/blog-detail/${blog?.url}`} className='text-black hover:text-burgandy'>{blog?.title ? blog?.title : ''}</Link></h2>
                                                    <p className='md:leading-6 md:text-base text-sm leading-5 font-Nunito_Regular text-light_blk pb-5'>{parse(blog?.long_desc ? blog?.long_desc : '')}</p>
                                                    <Link to={`/blog-detail/${blog?.url}`} className='text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_Bold'><i className="fa-solid fa-minus mr-2"></i> Read more</Link>
                                                </div>
                                            )
                                        }
                                    </>
                            }
                        </div>
                        {
                            blogs === '' || blogs.length === 0
                            &&
                            <>
                                <p className='text-center text-lg font-Nunito_SemiBold text-black'>No blogs found</p>
                            </>
                        }
                    </div>

                    <div className='float-left w-full font-Nunito_Regular text-center py-14 pt-8 border-t my-5 relative'>
                        <img className='w-[80px] float-left absolute top-14 right-1' src={`${process.env.REACT_APP_FRONT_URL}assets/images/blog_list_shap2.svg`} alt="" />
                        {
                            last_page > 1
                            &&
                            <Pagination totalCount={total_count} perPageRecord={length} handlePageClick={(data) => { setPage(data.selected + 1); setScroll(); }} />
                        }
                    </div>
                </div>
            </div>

            <div className="clear-both"></div>
            <div className='bg-gray-100 float-left w-full relative'>
                <img className='w-[180px] float-left absolute inset-y-2/4 left-0 2xl:block hidden' src={`${process.env.REACT_APP_FRONT_URL}assets/images/blog_list_shap3.svg`} alt="" />
                <div className='hidden md:block absolute right-0 top-0 bottom-0 pl-5 w-6/12 h-full' style={{ backgroundImage: `url(${process.env.REACT_APP_FRONT_URL}assets/images/blog_newsltr.jpg)`, backgroundRepeat: 'no-repeat', backgroundPosition: '1.25rem 0', backgroundSize: 'cover', }}></div>
                <div className='container m-auto px-4'>
                    <div className='float-left md:w-6/12 md:pr-5 w-full py-10 font-Nunito_Regular'>
                        <div className='float-left w-full'>
                            <h2 className='lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold'>Subscribe to Newsletter</h2>
                            <span className='w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2'></span>
                            <div className="clear-both"></div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className='float-left w-full py-3 relative'>
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    placeholder="Your Name"
                                    className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-4 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                />
                                {errors.name && touched.name ? <small className='text-red-600'>{errors.name}</small> : null}
                            </div>
                            <div className='float-left w-full py-3 relative'>
                                <input
                                    id="email"
                                    name="email"
                                    type="text"
                                    placeholder="Your E-mail"
                                    className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-4 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                                {errors.email && touched.email ? <small className='text-red-600'>{errors.email}</small> : null}
                            </div>
                            <div className='float-left lg:w-6/12 sm:w-6/12 w-full px-2 py-3 relative overflow-hidden'>
                                <ReCAPTCHA id="captcha" name="captcha" sitekey={constants.RECAPTCHA_SITE_KEY} ref={captchaRef} />
                                {errors.captcha && touched.captcha ? <small className='text-red-600'>{errors.captcha}</small> : null}
                            </div>
                            <div className='float-left w-full my-2 lg:my-0 md:pt-10 pt-5 py-3 pb-0 text-center'>
                                {
                                    proccessing
                                    &&
                                    <div className='absolute bg-gray-100/60 left-0 right-0 top-0 bottom-0 flex items-center justify-center'>
                                        <img src={constants.FORM_LOADER} alt="" width={50} />
                                    </div>
                                }
                                <button type='submit' className='md:text-base text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_SemiBold'><i className="fa-solid fa-minus mr-2"></i> Subscribe</button>
                            </div>
                        </form>
                        <div className="clear-both"></div>
                        {success_msg !== '' && typeof success_msg !== 'undefined' ?
                            <p className='text-green-600 text-lg pt-5 mb-0 font-Nunito_SemiBold text-center'>{success_msg}</p> : null}
                        {error_msg !== '' && typeof error_msg !== 'undefined' ?
                            <p className='text-red-600 text-lg pt-5 mb-0 font-Nunito_SemiBold text-center'>{error_msg}</p> : null}
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>
        </>
    )
};

export default Blog;