import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'flowbite-react';
import axios from 'axios';
import Fancybox from 'modules/fragments/FancyBox';
import { GalleryLoader } from 'components/interfaces/SkeletonLoader';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import constants from 'helpers/constants';

const Review = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [categories, setCategories] = useState();
    const [category_id, setCategoryId] = useState('');
    const [reviews, setReviews] = useState([]);
    const [review_length, setReviewLength] = useState(8);
    const [current_count, setCurrentCount] = useState(0);
    const [total_count, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [banner, setBanner] = useState('');

    useEffect(() => {
        fetchCategories();
        fetchReviews();
        fetchBanner();
    }, [category_id, review_length]);

    const fetchBanner = async () => {
        let url = `${API_URL}get-banner/${17}`;
        const cat = await axios.get(url).then(function (response) {
            setBanner(response.data?.data?.banner_data);
        }).catch(function (error) {
            console.log('error : ', error);
        });
    }

    const fetchCategories = async (length = 10) => {
        let url = `${API_URL}get-reviews-category`;
        const cat = await axios.get(url).then(function (response) {
            setCategories(response.data.data);
        }).catch(function (error) {
            console.log('error : ', error);
        });
    }

    const fetchReviews = async () => {
        setLoading(true);
        let url = `${API_URL}get-reviews?is_customer_feedback=0&category_id=${category_id}&length=${review_length}&is_pagination=1`;
        const rev = await axios.get(url).then(function (response) {
            setReviews(response.data.data.reviews.data);
            setCurrentCount(response.data.data.reviews.to);
            setTotalCount(response.data.data.reviews.total);
            setLoading(false);
        }).catch(function (error) {
            console.log('error : ', error);
            setLoading(false);
        });
    }
    return (
        <>
            <div className='bg-white lg:h-[600px] h-[300px] relative'>
                <img className='h-full w-full object-cover float-left' src={banner?.image_url !== '' ? banner?.image_url : `${process.env.REACT_APP_FRONT_URL}assets/images/about_banner.jpg`} alt="" />
                <div className='absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-white flex items-center font-Nunito_SemiBold'>
                    <div className='container m-auto px-4'>
                        <Breadcrumb aria-label="Default breadcrumb example">
                            <Link to="/" className='uppercase font-Nunito_SemiBold text-black hover:text-burgandy'>
                                Home
                            </Link>
                            <Breadcrumb.Item className='uppercase'>
                                Reviews
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        {
                            banner !== null && typeof banner !== 'undefined'
                                ?
                                <>
                                    {parse(banner.short_description ? banner.short_description : '')}
                                </>
                                :
                                <>
                                    <h1 className='text-[24px] md:text-[36px] leading-[32px] md:leading-[46px] font-Nunito_Bold text-burgandy py-4'>Politeness Goes Far<br />Yet Costs Nothing</h1>
                                </>
                        }
                    </div>
                </div>
            </div>

            <div className="clear-both"></div>
            <div className='bg-white float-left w-full md:py-24 md:pb-10 py-12'>
                <div className='container m-auto px-4 relative'>
                    <img className='sm:w-[150px] w-[100px] float-left absolute sm:-top-[20px] top-[60px] right-1' src={`${process.env.REACT_APP_FRONT_URL}assets/images/testi_shap2.svg`} alt="" />
                    <div className='-mx-3'>
                        <div className='float-left w-full px-3'>
                            <h2 className='lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold'>Browse our wonderful reviews</h2>
                            <span className='w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2'></span>
                            <div className="clear-both"></div>
                            <ul className='flex sm:flex-row flex-wrap md:gap-14 gap-6 font-Nunito_Regular md:text-base text-sm py-5 mb-5'>
                                <li><Link onClick={() => { setCategoryId(''); setReviewLength(8) }} className={`${category_id === '' ? 'text-burgandy border-b border-burgandy pb-1' : 'text-[#696969]'} hover:text-burgandy hover:border-burgandy border-b pb-1`}>All Reviews</Link></li>
                                {
                                    categories !== '' && typeof categories !== 'undefined'
                                    &&
                                    <>

                                        {
                                            Object.keys(categories).map((index) =>
                                                <li key={index}><Link onClick={() => { setCategoryId(index); setReviewLength(8) }} className={`${category_id === index ? 'text-burgandy border-b border-burgandy pb-1' : 'text-[#696969]'} hover:text-burgandy border-b border-transparent hover:border-burgandy pb-1`}>{categories[index]}</Link></li>
                                            )
                                        }
                                    </>
                                }
                            </ul>
                        </div>
                        <div className="clear-both"></div>
                    </div>
                    <div className='float-left w-full'>
                        <div className='grid lg:grid-cols-4 lg:gap-6 md:grid-cols-3 md:gap-4 sm:grid-cols-2 sm:gap-4 grid-cols-2 gap-4'>
                            {
                                loading
                                    ?
                                    <>
                                        <GalleryLoader count={6} />
                                    </>
                                    :
                                    <>
                                        {
                                            reviews !== '' && reviews.length > 0 && typeof reviews !== 'undefined'
                                                ?
                                                <>
                                                    {
                                                        reviews.map((review, index) =>
                                                            <div key={index} className='float-left w-full relative pb-20 p-2 bg-white hover:bg-gray-100'>
                                                                <Fancybox>
                                                                    <button data-fancybox data-caption={review?.long_description ? review?.long_description : ''} data-src={review?.image_url ? review?.image_url : constants.NO_IMAGE} className="absolute top-0 left-0 bottom-0 right-0 z-10 cursor-pointer">
                                                                        <div className='float-left w-full h-[200px] relative'>
                                                                            <img className='h-full hidden w-full object-cover float-left' src={review?.image_url ? review?.image_url : constants.NO_IMAGE} alt="" />
                                                                        </div>
                                                                    </button>
                                                                </Fancybox>
                                                                <div className='float-left w-full h-[200px] relative'>
                                                                    <img className='h-full w-full object-cover float-left' src={review?.image_url ? review?.image_url : constants.NO_IMAGE} alt="" />
                                                                </div>
                                                                <div className='absolute left-0 top-0 right-0 bottom-0 flex items-start justify-end flex-col pb-4 pl-4 text-black font-Nunito_Regular'>
                                                                    {
                                                                        review?.category_name !== '' && review?.category_name !== null
                                                                        &&
                                                                        <p className='text-sm sm:pb-1'><i className="fa-solid fa-minus mr-2"></i> {review?.category_name}</p>
                                                                    }
                                                                    <h4 className='text-base'>{review?.testimonial_by}</h4>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                                :
                                                <>
                                                    <p className='text-center text-lg font-Nunito_SemiBold text-black'>No reviews found</p>
                                                </>
                                        }
                                    </>
                            }

                            <div className="clear-both"></div>
                        </div>
                        <div className='float-left w-full text-center pt-5'>
                            {
                                current_count < total_count
                                &&
                                <button onClick={() => { setReviewLength(review_length + 4) }} className="text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_SemiBold"><i className="fa-solid fa-minus mr-2"></i> Load More</button>
                            }
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>

            <div className="clear-both"></div>
            {/* <div className='bg-white float-left w-full md:py-24 md:pb-10 py-12 text-center'>
                <div className='container m-auto px-4 text-2xl font-Nunito_Bold relative'>
                    <img className='w-[100px] float-left absolute -top-[80px] right-1 sm:block hidden' src={`${process.env.REACT_APP_FRONT_URL}assets/images/review_page_shap.svg`} alt="" />
                    Google Review gose here
                </div>
            </div> */}

        </>
    )
};

export default Review;