const constants = {
    RECAPTCHA_SITE_KEY: '6LeKRoUUAAAAALRe_-mfCdIZEEx4MloHJx_8zVNa',
    RECAPTCHA_SECRET_KEY: '6LeKRoUUAAAAAPmHBMo0o3H5yA9QuXWKP8Z48ZOw',

    FORM_LOADER: '/assets/images/loader.gif',
    NO_IMAGE: '/assets/images/no_image.jpg',
    PAGE_NOT_FOUND_IMG: '/assets/images/404_page.jpg',
    MIN_CHAR:  3,
    MAX_CHAR:  10,
    MAX_CHAR_50:  50,
    MAX_CHAR_100:  100,
}

export default constants;



