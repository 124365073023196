import React, { useEffect, useState, useRef } from "react";
import { Label, Radio } from "flowbite-react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Select from "react-select";
import { WeddingInquirySchema } from "modules/validations/Schema";
import constants from "helpers/constants";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useEffectOnce from "hooks/useEffectOnce";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "./Loader";

const WeddingInquiry = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [success_msg, setSuccessMsg] = useState("");
  const [fetchingFields, setFetchingFields] = useState(false);

  const [error_msg, setErrorMsg] = useState("");
  const [titles, setTitles] = useState("");
  const [events, setEvents] = useState("");
  const [capacities, setCapacities] = useState("");
  const [foods, setFoods] = useState("");
  const [venues, setVenues] = useState("");
  const [proccessing, setProccessing] = useState(false);
  // const [eventType, setEventType] = useState([]);
  const [selectKey, setSelectKey] = useState(0);
  const [captchatoken, setCaptchatoken] = useState("");
  const [recaptchakey, setreCaptchakey] = useState(false);

  const captchaRef = useRef(null);
  const today =
    new Date().getFullYear() +
    "-" +
    ("0" + (new Date().getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + new Date().getDate()).slice(-2);

  useEffectOnce(() => {
    setreCaptchakey(true);
    fetchFields();
    return () => {
      setreCaptchakey(false);
    };
  }, []);

  const fetchFields = async () => {
    setFetchingFields(true); // Update state to indicate fetching has started
    let url = `${API_URL}get-contactus`;
    try {
      const response = await axios.get(url);
      setTitles(response.data.data.title);
      setEvents(response.data.data.type_of_events);
      setCapacities(response.data.data.capacity);
      setFoods(response.data.data.type_of_food);
      setVenues(response.data.data.venues);
      append(EVENTS_INITIAL_STATE);
      setFetchingFields(false); // Update state to indicate fetching has ended
    } catch (error) {
      console.log("error : ", error);
      setFetchingFields(false); // Update state in case of error
    }
  };

  const formOptions = {
    resolver: yupResolver(WeddingInquirySchema),
    mode: "OnChange",
  };
  const {
    register,
    values,
    touched,
    control,
    handleBlur,
    getValues,
    handleSubmit,
    formState,
    clearErrors,
    trigger,
    setError,
    setValue,
    reset,
  } = useForm(formOptions);
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({ name: "events", control });
  const EVENTS_INITIAL_STATE = {
    type_of_event_id: [],
    event_date: "",
    venue_id: "",
    // capacity_id: "",
    start_time: "",
    end_time: "",
    type_of_food_id: [],
    // is_ceremony_reception: "",
    is_veg: "",
    is_alchohol: "",
    message: "",
  };

  useEffect(() => {
    if (captchatoken) {
      clearErrors("captcha");
      trigger("captcha");
    }
  }, [captchatoken]);

  const [formValues, setFormValues] = useState({
    events: [EVENTS_INITIAL_STATE],
  });

  const setDate = (date, index) => {
    let field = document.getElementById(`event_date${index}`);
    field.value(date);
  };

  const handleChange = (fieldName, value, index) => {
    // Clear errors
    clearErrors(`events.${index}.${fieldName}`);
    // Set field value
    setValue(`events.${index}.${fieldName}`, value);
    // Trigger validation
    trigger(`events.${index}.${fieldName}`);
  };

  let addFormFields = () => {
    append(EVENTS_INITIAL_STATE);
  };

  let removeFormFields = (i) => {
    remove(i);
  };
  const saveInquiry = async (data) => {
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    if (token === null || token === "") {
      setError("captcha", { type: "focus", message: "Please verify captcha!" });
      return;
    } else {
      setErrorMsg("");
    }
    setProccessing(true);
    let url = `${API_URL}post-wedding-inquiry`;
    const response = await axios
      .post(url, data)
      .then(function (response) {
        if (response.data.status) {
          setSuccessMsg(response.data.message);
          setErrorMsg("");
          reset();
          setSelectKey((prevKey) => prevKey + 1);
          setTimeout(() => {
            setSuccessMsg("");
          }, 5000);
        } else {
          setSuccessMsg("");
          setErrorMsg(response.data.message);
        }
        setProccessing(false);
      })
      .catch(function (error) {
        console.log("error : ", error);
        setProccessing(false);
      });
  };

  const eventtypeOption = [
    ...Object.entries(events).map(([id, value]) => ({
      value: id,
      label: value,
    })),
  ];

  const EventFoodOption = [
    ...Object.entries(foods).map(([id, value]) => ({
      value: id,
      label: value,
    })),
  ];

  return (
    <>
      <div
        className="-mx-5 relative"
        //  className="-mx-5 flex flex-wrap"
      >
        {fetchingFields ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit(saveInquiry)}>
            {error_msg !== "" && typeof error_msg !== "undefined" ? (
              <p className="text-red-600 text-md pt-5 mb-0 font-Nunito_SemiBold text-center">
                {error_msg}
              </p>
            ) : null}
            <div className="float-left w-full flex flex-wrap">
              <div className="float-left w-full md:w-6/12 px-5 my-2 ">
                <select
                  {...register("title")}
                  id="title"
                  name="title"
                  className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none cursor-pointer"
                  onChange={(e) => {
                    clearErrors(`title`);
                    setValue(`title`, e.target.value);
                    // Trigger validation
                    trigger(`title`);
                  }}
                >
                  <option value="" className="text-gray-400">
                    Title
                  </option>
                  {titles !== "" &&
                    typeof titles !== "undefined" &&
                    Object.entries(titles).map(([id, value], index) => (
                      <option key={index} value={id}>
                        {value}
                      </option>
                    ))}
                </select>
                <small className="text-red-600">{errors?.title?.message}</small>
              </div>
              <div className="float-left w-full md:w-6/12 px-5 my-2">
                <input
                  {...register("name")}
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Your Name"
                  className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                  onChange={(e) => {
                    const trimmedValue = e.target.value.replace(/^\s+/, "");
                    setValue("name", trimmedValue);
                    clearErrors(`name`);

                    // Trigger validation
                    trigger(`name`);
                  }}
                />
                <small className="text-red-600">{errors?.name?.message}</small>
              </div>
              <div className="float-left w-full md:w-6/12 px-5 my-2">
                <input
                  {...register("phone")}
                  id="phone"
                  name="phone"
                  type="text"
                  placeholder="Contact Number e.g +91123456"
                  className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                  onChange={(e) => {
                    const inputValue = e.target.value.trim();

                    // Check if the input is a valid phone number format (digits with optional leading +)
                    if (/^\+?\d*$/.test(inputValue)) {
                      setValue("phone", inputValue);
                      clearErrors("phone");
                      trigger("phone");
                    } else {
                      // Display an error if the input is not in the correct format
                      setValue("phone", "");
                      setError("phone", {
                        type: "manual",
                        message: "Please enter a valid phone number.",
                      });
                    }
                  }}
                />

                <small className="text-red-600">{errors?.phone?.message}</small>
              </div>

              <div className="float-left w-full md:w-6/12 px-5 my-2">
                <input
                  {...register("email")}
                  id="email"
                  name="email"
                  type="text"
                  placeholder="E-mail"
                  className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                  onChange={(e) => {
                    const trimmedValue = e.target.value.trim();

                    clearErrors(`email`);
                    setValue(`email`, trimmedValue);
                    // Trigger validation
                    trigger(`email`);
                  }}
                />
                <small className="text-red-600">{errors?.email?.message}</small>
              </div>
            </div>
            <div className="float-left w-full flex flex-wrap">
              {fields.map((element, index) => (
                <div key={index} className="float-left w-full flex flex-wrap">
                  <div className="float-left w-full px-5 my-2">
                    <h3 className="text-lg font-Nunito_SemiBold float-left">
                      Event {index + 1} (If you have multiple events for which
                      you need quotes, please add them separately by clicking on
                      the 'Add Event' button below.)
                    </h3>
                    {index ? (
                      <div className="float-right">
                        <button
                          type="button"
                          className="transition-colors text-black bg-burgandy text-white w-[36px] h-[36px] bg-gray-100"
                          onClick={() => removeFormFields(index)}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div className="float-left w-full md:w-6/12 px-5 my-2 ">
                    <Controller
                      name={`events.${index}.type_of_event_id`}
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id={`type_of_event_id${index}`}
                          isMulti
                          key={selectKey}
                          value={eventtypeOption?.find(
                            (option) =>
                              option.value ===
                              getValues(`events.${index}.type_of_event_id`)
                          )}
                          placeholder="Type of Event (Can Select Multiple Options)"
                          options={eventtypeOption}
                          className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none event-select"
                          onChange={(selectedOptions) => {
                            handleChange(
                              "type_of_event_id",
                              selectedOptions.map((option) => option.value),
                              index
                            );
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              border: 0,
                              // This line disable the blue border
                              boxShadow: "none",
                              cursor: "pointer",
                            }),
                          }}
                        />
                      )}
                    />
                    <small className="text-red-600">
                      {errors?.events?.[index]?.type_of_event_id?.message}
                    </small>
                  </div>

                  <div className="float-left w-full md:w-6/12 px-5 my-2">
                    {/* <i className="fa-light fa-calendar-days absolute right-5 z-10 inset-y-1/2 -mt-[9px]"></i> */}
                    <input
                      type="date"
                      className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none cursor-pointer"
                      {...register(`events.${index}.event_date`)}
                      id={`event_date${index}`}
                      onChange={(e) =>
                        handleChange("event_date", e.target.value, index)
                      }
                      // defaultValue={today}
                      min={today}
                    />
                    <small className="text-red-600">
                      {errors?.events?.[index]?.event_date?.message}
                    </small>
                  </div>

                  <div className="float-left w-full md:w-6/12 px-5 my-2 relative">
                    <input
                      {...register(`events.${index}.venue_id`)}
                      id={`venue_id${index}`}
                      type="text"
                      placeholder="Add Venue (If you need help finding a venue, please check our venues page.)"
                      className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                      // onChange={(e) => {
                      //   clearErrors(`events.${index}.message`);
                      // }}
                      onChange={(e) => {
                        const trimmedValue = e.target.value.replace(/^\s+/, "");

                        handleChange("venue_id", trimmedValue, index);
                      }}
                    ></input>
                    <small className="text-red-600">
                      {errors?.events?.[index]?.venue_id?.message}
                    </small>
                  </div>
                  <div className="float-left w-full md:w-6/12 px-5 my-2">
                      <input
                        {...register(`events.${index}.number_of_guests`)}
                        id={`number_of_guests{index}`}
                        type="number"
                        min="10"
                        max="2000"
                        placeholder="Number of Guests"
                        className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                        // onChange={(e) => {
                        //   clearErrors(`events.${index}.message`);
                        // }}
                        onChange={(e) => {
                          const trimmedValue = e.target.value.replace(/^\s+/, "");

                          handleChange("number_of_guests", trimmedValue, index);
                        }}
                      ></input>
                      {/*<select
                        {...register(`events.${index}.number_of_guests`)}
                        id={`number_of_guests${index}`}
                        className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none cursor-pointer"
                        onChange={(e) => {
                          clearErrors(`number_of_guests`);
                          setValue(`number_of_guests`, e.target.value);
                          // Trigger validation
                          trigger(`number_of_guests`);
                        }}
                      >
                        <option value="" className="text-gray-400">
                          Number of Guests
                        </option>
                        {capacities !== "" &&
                          typeof capacities !== "undefined" &&
                          Object.entries(capacities).map(([id, value], index) => (
                            <option key={index} value={id}>
                              {value}
                            </option>
                          ))}
                      </select>*/}
                      <small className="text-red-600">
                      {errors?.events?.[index]?.number_of_guests?.message}
                      </small>
                    </div>
                  <div className="float-left w-full md:w-6/12 px-5 my-2">
                    <div className="float-left w-full lg:w-6/12 lg:pr-3 lg:mb-0 mb-4">
                      <input
                        type="time"
                        {...register(`events.${index}.start_time`)}
                        id={`start_time${index}`}
                        placeholder="Start time"
                        className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none start-time "
                        // onChange={(e) => {
                        //   clearErrors(`events.${index}.start_time`);
                        // }}
                        onChange={(e) =>
                          handleChange("start_time", e.target.value, index)
                        }
                      />
                      <small className="text-red-600">
                        {errors?.events?.[index]?.start_time?.message}
                      </small>
                    </div>
                    <div className="float-left w-full lg:w-6/12 lg:pl-3 lg:mb-0 mb-2">
                      <input
                        type="time"
                        {...register(`events.${index}.end_time`)}
                        id={`end_time${index}`}
                        className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none end-time"
                        onChange={(e) =>
                          handleChange("end_time", e.target.value, index)
                        }
                      />
                      <small className="text-red-600">
                        {errors?.events?.[index]?.end_time?.message}
                      </small>
                    </div>
                  </div>
                  <div className="float-left w-full md:w-6/12 px-5 my-2">
                    <Controller
                            name={`events.${index}.type_of_food_id`}
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                              <Select
                                {...field}
                                id={`type_of_food_id${index}`}
                                isMulti
                                key={selectKey}
                                value={EventFoodOption?.find(
                                  (option) =>
                                    option.value ===
                                    getValues(`events.${index}.type_of_food_id`)
                                )}
                                placeholder="Type of Food (Can Select Multiple Options)"
                                options={EventFoodOption}
                                className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none event-select"
                                onChange={(selectedOptions) =>
                                  handleChange(
                                    "type_of_food_id",
                                    selectedOptions.map(
                                      (option) => option.value
                                    ),
                                    index
                                  )
                                }
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    border: 0,
                                    // This line disable the blue border
                                    boxShadow: "none",
                                    cursor: "pointer",
                                  }),
                                }}
                              />
                            )}
                          />

                          <small className="text-red-600">
                            {errors?.events?.[index]?.type_of_food_id?.message}
                          </small>
                  </div>
                  <div className="flex flex-col md:flex-row">
                    <div className="w-full md:w-1/2">
                      <div className="flex flex-col">
                        <div className=" w-full  px-5 my-2">
                          
                        </div>
                        {/*bottom text area start here */}
                        <div className="w-full px-5 my-2 ">
                          <textarea
                            {...register(`events.${index}.message`)}
                            id={`message${index}`}
                            type="text"
                            placeholder="Please elaborate further on the details of the inquiry."
                            className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none resize-none h-[210px]"
                            // onChange={(e) => {
                            //   clearErrors(`events.${index}.message`);
                            // }}
                            onChange={(e) => {
                              const trimmedValue = e.target.value.replace(
                                /^\s+/,
                                ""
                              );

                              handleChange("message", trimmedValue, index);
                            }}
                          ></textarea>
                          <small className="text-red-600">
                            {errors?.events?.[index]?.message?.message}
                          </small>
                        </div>
                      </div>
                    </div>
                    {/*right side div end*/}
                    <div className="w-full md:w-1/2 px-5 my-2">
                      {index === 0 && (
                        <div className="float-left w-full my-2">
                          <fieldset
                            className="flex py-4 text-base text-black font-Nunito_Regular justify-between items-start flex-row gap-4 add-margin-right"
                            id="radio1"
                          >
                            <div className="flex items-start gap-5">
                              <legend className="font-Nunito_SemiBold">
                                Would you like to be added to our mailing list?
                              </legend>
                            </div>
                            <div className="flex items-end gap-5">
                              <div className="flex items-center gap-2">
                                <Radio
                                  {...register(`is_mailing_list`)}
                                  id="is_mailing_list"
                                  // checked={true}
                                  onChange={(e) => {
                                    clearErrors(`is_mailing_list`);
                                    setValue(`is_mailing_list`, e.target.value);
                                    // Trigger validation
                                    trigger(`is_mailing_list`);
                                  }}
                                  value="1"
                                />
                                <Label htmlFor="is_mailing_list-yes">Yes</Label>
                              </div>
                              <div className="flex items-center gap-2">
                                <Radio
                                  {...register(`is_mailing_list`)}
                                  id="is_mailing_listNo"
                                  value="0"
                                  onChange={(e) => {
                                    clearErrors(`is_mailing_list`);
                                    setValue(`is_mailing_list`, e.target.value);
                                    // Trigger validation
                                    trigger(`is_mailing_list`);
                                  }}
                                />
                                <Label htmlFor="is_mailing_listNo">No</Label>
                              </div>
                              <small className="text-red-600">
                                {errors?.is_mailing_list?.message}
                              </small>
                            </div>
                          </fieldset>
                        </div>
                      )}

                      <div className="float-left w-full my-2">
                        <fieldset
                          className="flex py-0 text-base text-black font-Nunito_Regular justify-between items-start flex-row gap-4 add-margin-right"
                          id="radio2"
                        >
                          <div className="flex items-start gap-5">
                            <legend className="font-Nunito_SemiBold">
                              Are you serving non-veg and veg food at this
                              event?{" "}
                              {/* <br />
                          just a reception party? */}
                            </legend>
                          </div>
                          <div className="flex items-end gap-5">
                            <div className="flex items-center gap-2">
                              <Radio
                                {...register(`events.${index}.is_veg`)}
                                id={`is_veg${index}`}
                                // onChange={(e) => {
                                //   clearErrors(
                                //     `events.${index}.is_ceremony_reception`
                                //   );
                                // }}
                                onChange={(e) =>
                                  handleChange("is_veg", e.target.value, index)
                                }
                                value="1"
                              />
                              <Label htmlFor={`is_veg${index}`}>Yes</Label>
                            </div>
                            <div className="flex items-center gap-2">
                              <Radio
                                {...register(`events.${index}.is_veg`)}
                                id={`is_vegNo${index}`}
                                // onChange={(e) => {
                                //   clearErrors(
                                //     `events.${index}.is_ceremony_reception`
                                //   );
                                // }}
                                onChange={(e) =>
                                  handleChange("is_veg", e.target.value, index)
                                }
                                value="0"
                              />
                              <Label htmlFor={`is_vegNo${index}`}>No</Label>
                            </div>
                            <small className="text-red-600">
                              {errors?.events?.[index]?.is_veg?.message}
                            </small>
                          </div>
                        </fieldset>
                      </div>
                      <div className="float-left w-full my-2">
                        <fieldset
                          className="flex py-4 text-base text-black font-Nunito_Regular justify-between items-start flex-row gap-4 add-margin-right"
                          id="radio3"
                        >
                          <div className="flex items-start gap-5">
                            <legend className="font-Nunito_SemiBold">
                              Can you confirm if you are serving alcohol at this
                              event?
                            </legend>
                          </div>
                          <div className="flex items-end gap-5">
                            <div className="flex items-center gap-2">
                              <Radio
                                {...register(`events.${index}.is_alchohol`)}
                                id={`is_alchohol${index}`}
                                // onChange={(e) => {
                                //   clearErrors(`events.${index}.is_alchohol`);
                                // }}
                                onChange={(e) =>
                                  handleChange(
                                    "is_alchohol",
                                    e.target.value,
                                    index
                                  )
                                }
                                value="1"
                              />
                              <Label htmlFor={`is_alchohol${index}`}>Yes</Label>
                            </div>
                            <div className="flex items-center gap-2">
                              <Radio
                                {...register(`events.${index}.is_alchohol`)}
                                id={`is_alchoholNo${index}`}
                                // onChange={(e) => {
                                //   clearErrors(`events.${index}.is_alchohol`);
                                // }}
                                onChange={(e) =>
                                  handleChange(
                                    "is_alchohol",
                                    e.target.value,
                                    index
                                  )
                                }
                                value="0"
                              />
                              <Label htmlFor={`is_alchoholNo${index}`}>
                                No
                              </Label>
                            </div>
                            <small className="text-red-600">
                              {errors?.events?.[index]?.is_alchohol?.message}
                            </small>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="float-left w-full px-5">
                <div className="float-right">
                  <button
                    type="button"
                    className="transition-colors text-white bg-burgandy hover:bg-gray-800 text-sm py-2 px-3 font-Nunito_SemiBold"
                    onClick={() => addFormFields()}
                  >
                    + Add Event
                  </button>
                </div>
              </div>
              <div className="float-left w-full px-5 py-3 relative overflow-hidden">
                {recaptchakey && (
                  <ReCAPTCHA
                    id="captcha"
                    name="captcha"
                    sitekey={constants.RECAPTCHA_SITE_KEY}
                    ref={captchaRef}
                    onChange={(e) => {
                      setCaptchatoken(e);
                    }}
                    asyncScriptOnLoad={console.log}
                    onErrored={console.log}
                  />
                )}

                <small className="text-red-600">
                  {errors?.captcha?.message}
                </small>
              </div>
              <div className="float-left w-full my-2 lg:my-0 md:pt-10 pt-5 py-3 pb-0 text-center">
                {proccessing && (
                  <div className="absolute bg-gray-100/60 left-0 right-0 top-0 bottom-0 flex items-center justify-center">
                    <img src={constants.FORM_LOADER} alt="" width={50} />
                  </div>
                )}
                <button
                  type="submit"
                  className="md:text-base text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_SemiBold"
                >
                  <i className="fa-solid fa-minus mr-2"></i> Send Enquiry
                </button>
              </div>
            </div>
          </form>
        )}

        <div className="clear-both"></div>
        {success_msg !== "" && typeof success_msg !== "undefined" ? (
          <p className="text-green-600 text-lg pt-5 mb-0 font-Nunito_SemiBold text-center">
            {success_msg}
          </p>
        ) : null}
      </div>
      <div className="clear-both"></div>
    </>
  );
};
export default WeddingInquiry;
