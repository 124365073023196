import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { t } = useTranslation();
    const [footer, setFooter] = useState('');
    const [social, setSocial] = useState('');

    useEffect(() => {
        fetchContent();
    }, []);

    const fetchContent = async () => {
        let url = `${API_URL}get-header-footer-data`;
        const footer = await axios.get(url).then(function (response) {
            setFooter(response.data.data.footer);
            setSocial(response.data.data.footer.social_media);
        }).catch(function (error) {
            console.log('error : ', error);
        });
    }

    return (
        <>
            <div className="clear-both"></div>
            <footer className="bg-white pb-0 relative overflow-hidden">
                <div className="w-full float-left absolute left-0 right-0 top-0 bottom-[60px] overflow-hidden">
                    <img className='w-[240px] float-left absolute bottom-0 right-1 hidden 2xl:block' src={`${process.env.REACT_APP_FRONT_URL}assets/images/footer_shap1.svg`} alt="" />
                    <img className='w-[180px] float-left absolute bottom-[20%] 2xl:left-10 lg:left-[42%] sm:left-[50%] hidden sm:block' src={`${process.env.REACT_APP_FRONT_URL}assets/images/footer_shap2.svg`} alt="" />
                </div>
                <div className='container m-auto px-4 relative z-[1]'>
                    <div className='lg:flex lg:flex-wrap justify-evenly pt-12 pb-10'>
                        <div className="flex flex-col">
                            <div className='float-left w-full pb-1'>
                                <Link to={`/`}><img className="md:w-[140px] w-24" src={footer?.logo ? footer?.logo : `${process.env.REACT_APP_FRONT_URL}assets/images/logo.jpg`} alt="" /></Link>
                            </div>
                            <div className='float-left w-full md:py-6 py-4'>
                                <p className='text-sm text-light_blk leading-6'>{footer?.address}</p>
                            </div>
                            <div className='float-left w-full py-5 pt-0'>
                                <p className='text-sm leading-6'>
                                    <a href={`tel:${footer?.phone}`} className='text-light_blk font-Nunito_SemiBold hover:text-burgandy transition-colors'>T: {footer?.phone}</a><br />
                                    <a href={`mailto:${footer?.email}`} className='text-light_blk font-Nunito_SemiBold hover:text-burgandy transition-colors'>{footer?.email}</a>
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className='float-left w-full pb-3'>
                                <ul className='flex gap-4 md:pt-0 pt-3'>
                                    {
                                        social !== '' && typeof social !== 'undefined'
                                        &&
                                        social.map((link, index) =>
                                            <li key={index}><a href={link?.url} title={link?.link_title} target="_blank" className='transition-colors text-black hover:bg-burgandy hover:text-white w-[36px] h-[36px] leading-9 inline-block text-center bg-gray-100'><i className={link?.title}></i></a></li>
                                        )
                                    }
                                </ul>
                            </div>
                            <div className='float-left w-full md:py-6 py-3'>
                                <p className='text-sm text-light_blk leading-7'><strong className='font-Nunito_SemiBold text-black'>Opening Times :</strong><br />{footer?.opening_time}</p>
                            </div>
                            <div className='float-left w-full md:pt-3 pt-1'>
                                <p className='text-sm text-light_blk leading-8'><strong className='font-Nunito_SemiBold text-black text-[15px]'>Keep in Touch with us! we are available to help</strong><br />
                                    <Link to={`/contact-us`} className="transition-colors text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_SemiBold "><i className="fa-solid fa-minus mr-2"></i> Contact us</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
                <div className="clear-both"></div>
                <div className='bg-gray-100 py-3'>
                    <div className='container m-auto px-4'>
                        <div className='sm:flex sm:flex-wrap sm:justify-between sm:items-center'>
                            <p className='text-[13px] text-black font-Nunito_SemiBold sm:text-left text-center pb-3 sm:pb-0'>© {new Date().getFullYear()} Preeti Catering. All Rights Reserved</p>
                            <p className='text-[13px] text-black font-Nunito_SemiBold sm:text-left text-center pb-3 sm:pb-0'>
                                <a className='flex flex-wrap items-center gap-2 justify-center' href="http://technoinfonet.com/" target="_blank">
                                    Powered by <img className="h-[36px]" src={`${process.env.REACT_APP_FRONT_URL}assets/images/techno_logo.svg`} alt="" />
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="hidden">
                <div className='hidden container m-auto px-4'>
                    <p className='hidden text-base uppercase leading-7'></p>
                    <h1 className='hidden text-[26px] md:text-[44px] leading-[34px] md:leading-[50px] font-Nunito_Bold text-burgandy py-4'></h1>
                    <p className='hidden text-base leading-7 md:block'></p>
                </div>
                <h1 className='hidden text-[24px] md:text-[36px] leading-[32px] md:leading-[46px] font-Nunito_Bold text-burgandy py-4'></h1>
            </div>
        </>
    )
};

export default Footer;