import React, { useEffect, useState, useRef } from "react";
import { Breadcrumb, Label, Radio } from "flowbite-react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Select from "react-select";
import {
  EventInquirySchema,
  FoodInquirySchema,
  WeddingInquirySchema,
} from "modules/validations/Schema";
import constants from "helpers/constants";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useEffectOnce from "hooks/useEffectOnce";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "./Loader";

const FoodOrder = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [success_msg, setSuccessMsg] = useState("");
  const [error_msg, setErrorMsg] = useState("");
  const [fetchingFields, setFetchingFields] = useState(false);

  const [titles, setTitles] = useState("");
  const [events, setEvents] = useState("");
  const [capacities, setCapacities] = useState("");
  const [foods, setFoods] = useState("");
  const [venues, setVenues] = useState("");
  const [proccessing, setProccessing] = useState(false);
  const [captchatoken, setCaptchatoken] = useState("");
  const [recaptchakey, setreCaptchakey] = useState(false);
  const [selectKey, setSelectKey] = useState(0);

  const captchaRef = useRef(null);
  const today =
    new Date().getFullYear() +
    "-" +
    ("0" + (new Date().getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + new Date().getDate()).slice(-2);

  useEffectOnce(() => {
    setreCaptchakey(true);

    fetchFields();
    return () => {
      setreCaptchakey(false);
    };
  }, []);

  const fetchFields = async () => {
    setFetchingFields(true); // Update state to indicate fetching has started
    let url = `${API_URL}get-contactus`;
    try {
      const response = await axios.get(url);
      setTitles(response.data.data.title);
      setEvents(response.data.data.type_of_events);
      setCapacities(response.data.data.capacity);
      setFoods(response.data.data.type_of_food);
      setVenues(response.data.data.venues);
      // append(EVENTS_INITIAL_STATE);
      setFetchingFields(false); // Update state to indicate fetching has ended
    } catch (error) {
      console.log("error : ", error);
      setFetchingFields(false); // Update state in case of error
    }
  };

  const formOptions = {
    resolver: yupResolver(FoodInquirySchema),
    mode: "All",
    defaultValue: {
      event_date: "",
      no_of_people: "",
      message: "",
    },
  };
  const {
    register,
    values,
    touched,
    control,
    handleBlur,
    handleSubmit,
    formState,
    getValues,
    clearErrors,
    trigger,
    setError,
    setValue,
    reset,
  } = useForm(formOptions);
  const { errors } = formState;

  const setDate = (date, index) => {
    let field = document.getElementById(`date`);
    field.value(date);
  };

  useEffect(() => {
    if (captchatoken) {
      clearErrors("captcha");
      trigger("captcha");
    }
  }, [captchatoken]);

  const handleChange = (fieldName, value, index) => {
    // Clear errors
    clearErrors(`fieldName`);
    // Set field value
    setValue(`fieldName`, value);
    // Trigger validation
    trigger(`fieldName`);
  };

  const saveInquiry = async (data) => {
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    if (token === null || token === "") {
      setError("captcha", { type: "focus", message: "Please verify captcha!" });
      return;
    } else {
      setErrorMsg("");
    }
    setProccessing(true);
    let url = `${API_URL}food-order-enquiry`;
    const response = await axios
      .post(url, data)
      .then(function (response) {
        if (response.data.status) {
          setSuccessMsg(response.data.message);
          setErrorMsg("");
          reset();
          setSelectKey((prevKey) => prevKey + 1);
          setTimeout(() => {
            setSuccessMsg("");
          }, 5000);
        } else {
          setSuccessMsg("");
          setErrorMsg(response.data.message);
        }
        setProccessing(false);
      })
      .catch(function (error) {
        console.log("error : ", error);
        setProccessing(false);
      });
  };

  const eventtypeOption = [
    ...Object.entries(events).map(([id, value]) => ({
      value: id,
      label: value,
    })),
  ];

  const EventFoodOption = [
    ...Object.entries(foods).map(([id, value]) => ({
      value: id,
      label: value,
    })),
  ];

  return (
    <>
      <div className="-mx-5 relative">
        <h2 className="text-lg font-Nunito_Regular text-center w-full mx-auto mb-4">
          Please fill out this form to receive our prices for{" "}
          <strong className="font-Nunito_SemiBold">take-aways </strong>/{" "}
          <strong className="font-Nunito_SemiBold">food orders</strong>
        </h2>
        {fetchingFields ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit(saveInquiry)}>
            {error_msg !== "" && typeof error_msg !== "undefined" ? (
              <p className="text-red-600 text-md pt-5 mb-0 font-Nunito_SemiBold text-center">
                {error_msg}
              </p>
            ) : null}
            <div className="float-left w-full flex flex-wrap">
              <div className="float-left w-full md:w-6/12 px-5 my-2">
                <select
                  {...register("title")}
                  id="title"
                  name="title"
                  className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none cursor-pointer"
                  onChange={(e) => {
                    clearErrors(`title`);
                    setValue(`title`, e.target.value);
                    // Trigger validation
                    trigger(`title`);
                  }}
                >
                  <option value="" className="text-gray-400">
                    Title
                  </option>
                  {titles !== "" &&
                    typeof titles !== "undefined" &&
                    Object.entries(titles).map(([id, value], index) => (
                      <option key={index} value={id}>
                        {value}
                      </option>
                    ))}
                </select>
                <small className="text-red-600">{errors?.title?.message}</small>
              </div>
              <div className="float-left w-full md:w-6/12 px-5 my-2">
                <input
                  {...register("name")}
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Your Name"
                  className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                  onChange={(e) => {
                    const trimmedValue = e.target.value.replace(/^\s+/, "");
                    setValue("name", trimmedValue);
                    clearErrors(`name`);

                    // Trigger validation
                    trigger(`name`);
                  }}
                />
                <small className="text-red-600">{errors?.name?.message}</small>
              </div>
              <div className="float-left w-full md:w-6/12 px-5 my-2">
                <input
                  {...register("phone")}
                  id="phone"
                  name="phone"
                  type="text"
                  placeholder="Contact Number e.g +91123456"
                  className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                  onChange={(e) => {
                    const inputValue = e.target.value.trim();

                    // Check if the input is a valid phone number format (digits with optional leading +)
                    if (/^\+?\d*$/.test(inputValue)) {
                      setValue("phone", inputValue);
                      clearErrors("phone");
                      trigger("phone");
                    } else {
                      // Display an error if the input is not in the correct format
                      setValue("phone", "");
                      setError("phone", {
                        type: "manual",
                        message: "Please enter a valid phone number.",
                      });
                    }
                  }}
                />

                <small className="text-red-600">{errors?.phone?.message}</small>
              </div>

              <div className="float-left w-full md:w-6/12 px-5 my-2">
                <input
                  {...register("email")}
                  id="email"
                  name="email"
                  type="text"
                  placeholder="E-mail"
                  className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                  onChange={(e) => {
                    const trimmedValue = e.target.value.trim();

                    clearErrors(`email`);
                    setValue(`email`, trimmedValue);
                    // Trigger validation
                    trigger(`email`);
                  }}
                />
                <small className="text-red-600">{errors?.email?.message}</small>
              </div>
            </div>
            <div className="float-left w-full flex flex-wrap">
              <div className="float-left w-full flex flex-wrap">
                <div className="float-left w-full md:w-6/12 px-5 my-2">
                  <input
                    type="date"
                    className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none cursor-pointer"
                    {...register(`event_date`)}
                    id={`event_date`}
                    onChange={(e) => {
                      clearErrors(`event_date`);
                      setValue(`event_date`, e.target.value);
                      // Trigger validation
                      trigger(`event_date`);
                    }}
                    // defaultValue={today}
                    min={today}
                  />
                  <small className="text-red-600">
                    {errors?.event_date?.message}
                  </small>
                </div>

                <div className="float-left w-full md:w-6/12 px-5 my-2">
                  <input
                    {...register("no_of_people")}
                    id="no_of_people"
                    name="no_of_people"
                    type="text"
                    placeholder="Add Number of People Requiring Catering"
                    className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const numericValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters

                      setValue("no_of_people", numericValue);
                      clearErrors("no_of_people");
                      trigger("no_of_people");
                    }}
                  />
                  <small className="text-red-600">
                    {errors?.no_of_people?.message}
                  </small>
                </div>

                <div className="float-left w-full md:w-6/12 px-5 my-2 relative">
                  <textarea
                    {...register(`message`)}
                    id={`message`}
                    type="text"
                    // placeholder="Add a Bit More About your order..."
                    placeholder="Provide additional details about your food order inquiry."
                    className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none resize-none h-[210px]"
                    // onChange={(e) => {
                    //   clearErrors(`events.${index}.message`);
                    // }}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.replace(/^\s+/, "");
                      clearErrors("message");
                      setValue("message", trimmedValue);
                      trigger("message");
                    }}
                  ></textarea>
                  <small className="text-red-600">
                    {errors?.message?.message}
                  </small>
                </div>
                <div className="float-right w-full md:w-6/12 px-5 my-2">
                  <div className="float-left w-full my-2">
                    <div
                      className="flex py-0 text-base text-black font-Nunito_Regular justify-between items-start flex-row gap-4"
                      id="radio2"
                    >
                      <div className="flex items-start gap-5">
                        <legend className="font-Nunito_SemiBold">
                          Would you like to be added to our mailing list?{" "}
                          {/* <br />
                          just a reception party? */}
                        </legend>
                      </div>
                      <div className="flex items-end gap-5">
                        <div className="flex items-center gap-2">
                          <Radio
                            {...register(`is_mailing_list`)}
                            id="is_mailing_list"
                            onChange={(e) => {
                              clearErrors(`is_mailing_list`);
                              setValue(`is_mailing_list`, e.target.value);
                              // Trigger validation
                              trigger(`is_mailing_list`);
                            }}
                            value="1"
                          />
                          <Label htmlFor="is_mailing_list-yes">Yes</Label>
                        </div>
                        <div className="flex items-center gap-2">
                          <Radio
                            {...register(`is_mailing_list`)}
                            id="is_mailing_listNo"
                            value="0"
                            onChange={(e) => {
                              clearErrors(`is_mailing_list`);
                              setValue(`is_mailing_list`, e.target.value);
                              // Trigger validation
                              trigger(`is_mailing_list`);
                            }}
                          />
                          <Label htmlFor="is_mailing_listNo">No</Label>
                        </div>
                        <small className="text-red-600">
                          {errors?.is_mailing_list?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="float-left w-full px-5 py-3 relative overflow-hidden">
                {recaptchakey && (
                  <ReCAPTCHA
                    // key={captchakey}
                    id="captcha"
                    name="captcha"
                    sitekey={constants.RECAPTCHA_SITE_KEY}
                    ref={captchaRef}
                    onChange={(e) => {
                      setCaptchatoken(e);
                    }}
                    asyncScriptOnLoad={console.log}
                    onErrored={console.log}
                  />
                )}
                <small className="text-red-600">
                  {errors?.captcha?.message}
                </small>
              </div>
              <div className="float-left w-full my-2 lg:my-0 md:pt-10 pt-5 py-3 pb-0 text-center">
                {proccessing && (
                  <div className="absolute bg-gray-100/60 left-0 right-0 top-0 bottom-0 flex items-center justify-center">
                    <img src={constants.FORM_LOADER} alt="" width={50} />
                  </div>
                )}
                <button
                  type="submit"
                  className="md:text-base text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_SemiBold"
                >
                  <i className="fa-solid fa-minus mr-2"></i> Send Enquiry
                </button>
              </div>
            </div>
            <div className="clear-both"></div>
            {success_msg !== "" && typeof success_msg !== "undefined" ? (
              <p className="text-green-600 text-lg pt-5 mb-0 font-Nunito_SemiBold text-center">
                {success_msg}
              </p>
            ) : null}
          </form>
        )}
      </div>
      <div className="clear-both"></div>
    </>
  );
};
export default FoodOrder;
