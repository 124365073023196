import React, { useEffect, useState, useTransition, useRef } from "react";
import { Breadcrumb, Label, Radio } from "flowbite-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import constants from "../../helpers/constants";

import { ContactUsSchema } from "modules/validations/Schema";
import WeddingInquiry from "../pages/forms/WeddingInquiry";
import ReCAPTCHA from "react-google-recaptcha";
import parse from "html-react-parser";
import GeneralInquiry from "../pages/forms/GeneralInquiry";
import ContactInquiry from "../pages/forms/ContactInquiry";
import EventInquiry from "../pages/forms/EventInquiry";
import FoodOrder from "../pages/forms/FoodOrder";

const NewContactUs = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const history = useNavigate();
  const queryParameters = new URLSearchParams(location.search);
  const initialEnquiry = queryParameters.get("enquiry") || "General Enquiry";
  const [startDate, setStartDate] = useState(new Date());
  const [value, onChange] = useState("10:00");
  const [success_msg, setSuccessMsg] = useState("");
  const [selectedEnquiry, setSelectedEnquiry] = useState(
    decodeURIComponent(initialEnquiry)
  );
  const [error_msg, setErrorMsg] = useState("");
  const { t } = useTransition();
  const [content, setContent] = useState("");
  const [categories, setCategories] = useState("");
  const [titles, setTitles] = useState("");
  const [proccessing, setProccessing] = useState(false);
  const [form_view, setFormView] = useState(0);
  const [banner, setBanner] = useState("");
  const [captchatoken, setCaptchatoken] = useState("");

  const captchaRef = useRef(null);

  const enquiry = queryParameters.get("enquiry");

  useEffect(() => {
    fetchContent();
    fetchFields();
    fetchBanner();
  }, []);

  useEffect(() => {
    // Update the URL query parameter when the state changes
    const updatedURL = `/contact-us?enquiry=${encodeURIComponent(
      selectedEnquiry
    )}`;

    history(updatedURL, { replace: true });
  }, [selectedEnquiry, history]);

  const fetchBanner = async () => {
    let url = `${API_URL}get-banner/${22}`;
    const cat = await axios
      .get(url)
      .then(function (response) {
        setBanner(response.data?.data?.banner_data);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };

  const fetchContent = async () => {
    let url = `${API_URL}get-header-footer-data`;
    const content = await axios
      .get(url)
      .then(function (response) {
        setContent(response.data.data.footer);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };
  const fetchFields = async () => {
    let url = `${API_URL}get-contactus`;
    const cat = await axios
      .get(url)
      .then(function (response) {
        setCategories(response.data.data.categories);
        setTitles(response.data.data.title);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };

  const initialValues = {
    category_id: "",
    title: "",
    name: "",
    phone: "",
    email: "",
    message: "",
    is_mailing_list: "",
    captcha: "",
  };
  const {
    errors,
    values,
    touched,
    isMulti,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setErrors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ContactUsSchema,
    errors: "",
    onSubmit: async (values, action) => {
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      if (token === null || token === "") {
        setErrors({ captcha: "Please verify captcha!" });
        return;
      } else {
        setErrorMsg("");
      }
      setProccessing(true);
      let url = `${API_URL}post-contact-us`;
      const response = await axios
        .post(url, values)
        .then(function (response) {
          if (response.data.status) {
            setSuccessMsg(response.data.message);
            setErrorMsg("");
            action.resetForm();
          } else {
            setSuccessMsg("");
            setErrorMsg(response.data.message);
          }
          setProccessing(false);
        })
        .catch(function (error) {
          console.log("error : ", error);
          setProccessing(false);
        });
    },
  });

  return (
    <>
      <div className="bg-white lg:h-[600px] h-[300px] relative">
        <img
          className="h-full w-full object-cover float-left"
          src={
            banner?.image_url !== ""
              ? banner?.image_url
              : `${process.env.REACT_APP_FRONT_URL}assets/images/gallery_banner.jpg`
          }
          alt=""
        />
        <div className="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-white flex items-center font-Nunito_SemiBold">
          <div className="container m-auto px-4">
            <Breadcrumb aria-label="Default breadcrumb example">
              <Link
                to="/"
                className="uppercase font-Nunito_SemiBold text-black hover:text-burgandy"
              >
                Home
              </Link>
              <Breadcrumb.Item className="uppercase">
                Contact Us
              </Breadcrumb.Item>
            </Breadcrumb>
            {banner !== null && typeof banner !== "undefined" ? (
              <>
                {parse(
                  banner.short_description ? banner.short_description : ""
                )}
              </>
            ) : (
              <>
                <h1 className="text-[24px] md:text-[36px] leading-[32px] md:leading-[46px] font-Nunito_Bold text-burgandy py-4">
                  Get in Touch
                  <br />
                  With us Today
                </h1>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="clear-both"></div>
      <div className="bg-gray-100 float-left w-full md:py-24 py-12 overflow-hidden">
        <div className="container m-auto px-4 relative">
          <img
            class="sm:w-[170px] w-[140px] float-left absolute md:-bottom-[95px] -bottom-[45px] xl:-left-10 left-2"
            src={`${process.env.REACT_APP_FRONT_URL}assets/images/banner_shape.svg`}
            alt=""
          />
          <div className="float-left w-full">
            <h2 className="lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold">
              Get in touch with our team for more information
            </h2>
            <span className="w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2"></span>
            <div className="clear-both"></div>
            <h5 className="text-xl font-Nunito_Regular leading-8 text-light_blk">
              If you are Interested in our{" "}
              <strong className="font-Nunito_SemiBold">
                Wedding Catering Services
              </strong>{" "}
              or{" "}
              <strong className="font-Nunito_SemiBold">
                Food Order Services
              </strong>
              ,Please
              <Link
                to={`/book-a-call`}
                className="font-Nunito_SemiBold hover:text-black text-burgandy"
              >
                {" "}
                book a call
              </Link>
              <br />
              OR You can Call us on{" "}
              <a
                href={`tel:${content?.phone}`}
                className="font-Nunito_SemiBold hover:text-black text-burgandy"
              >
                {content?.phone}
              </a>{" "}
              or E-mail{" "}
              <a
                href={`mailto:${content?.email}`}
                className="font-Nunito_SemiBold hover:text-black text-burgandy"
              >
                {content?.email}
              </a>{" "}
            </h5>
          </div>
          <div className="float-left w-full pt-10">
            <ul
              className="flex sm:flex-col flex-wrap  font-Nunito_Regular md:text-base text-sm py-5"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="float-left w-full md:w-4/12 px-5 ml-[-19px]">
                {/* <label>Select your type of enquiry from below</label> */}
                <h3 className="text-lg font-Nunito_SemiBold float-left ml-2">
                  Select your type of enquiry from below
                </h3>
                <div className="float-left w-full my-2">
                  <select
                    id="enquiry"
                    name="enquiry"
                    className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none cursor-pointer"
                    onChange={(e) => {
                      setSelectedEnquiry(e.target.value);
                    }}
                    onBlur={handleBlur}
                    value={selectedEnquiry}
                  >
                    {/* <option value="General Enquiry" className="text-gray-400">
                      Type of Enquiry
                    </option> */}
                    <option value="General Enquiry">General Enquiry</option>
                    <option value="Wedding Enquiry">Wedding Enquiry</option>
                    <option value="Event Enquiry">Event Enquiry</option>
                    <option value="Food Order">Food Order</option>
                  </select>
                </div>
              </div>
              <br />
              <li>
                <button
                  type="button"
                  className={`${
                    form_view === 0
                      ? "text-burgandy border-burgandy"
                      : "text-[#696969] hover:text-burgandy border-transparent hover:border-burgandy "
                  } border-b pb-1 ml-[7px]`}
                >
                  {selectedEnquiry}
                </button>
              </li>
            </ul>

            <div className="float-left w-full">
              {selectedEnquiry === "Contact Us" && (
                <div>
                  <ContactInquiry
                    captchaRef={captchaRef}
                    captchatoken={captchatoken}
                  />
                </div>
              )}
              {selectedEnquiry === "General Enquiry" && (
                <div>
                  <GeneralInquiry />
                </div>
              )}

              {selectedEnquiry === "Wedding Enquiry" && (
                <div>
                  <WeddingInquiry />
                </div>
              )}
              {selectedEnquiry === "Event Enquiry" && (
                <div>
                  <EventInquiry />
                </div>
              )}
              {selectedEnquiry === "Food Order" && (
                <div>
                  <FoodOrder />
                </div>
              )}
              {/* <div className="float-left w-full px-5 py-3 relative overflow-hidden">
                <ReCAPTCHA
                  id="captcha"
                  name="captcha"
                  sitekey={constants.RECAPTCHA_SITE_KEY}
                  ref={captchaRef}
                  onChange={(e) => {
                    setCaptchatoken(e);
                  }}
                />
              </div> */}
            </div>
          </div>

          <div className="clear-both"></div>
        </div>
      </div>

      <div className="clear-both"></div>
      <div className="bg-white float-left w-full md:pt-24 pt-12">
        <div className="container m-auto px-4 relative">
          <img
            class="md:w-[140px] w-[100px] float-left absolute md:-top-[135px] -top-[85px] right-2"
            src={`${process.env.REACT_APP_FRONT_URL}assets/images/contact_shap.svg`}
            alt=""
          />
          <div className="float-left w-full">
            <h2 className="lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold">
              How to Find Us
            </h2>
            <span className="w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2"></span>
            <div className="clear-both"></div>
          </div>
        </div>
        <div className="float-left w-full relative min-h-[400px]">
          <iframe
            className="absolute left-0 top-0 right-0 bottom-0 w-full h-full"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4965.951923965643!2d-0.39804!3d51.513657!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb4747a94010153e9!2sPreeti%20Catering!5e0!3m2!1sen!2sus!4v1672825653377!5m2!1sen!2sus"
          />
        </div>
      </div>
    </>
  );
};

export default NewContactUs;
