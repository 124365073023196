import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const BlockLoader = ({ count, width, height }) => {
    return (
        <Skeleton
            count={count}
            style={{
                padding: '0.5rem',
                marginBottom: '0.4rem',
                width: width,
                height: height,
            }}
        />
    )
}

export const VanueLoader = ({ count }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <div key={index} className='float-left w-full border-b border-gray-200 py-10 overflow-hidden'>
                    <div className='-mx-3'>
                        <div className='float-left w-full lg:w-3/12 md:w-4/12 px-3'>
                            <Skeleton height={250} />
                            <div className='clear-both'></div>
                            <ul className='w-full float-left pt-6'>
                                <li className='float-left mt-1 mr-3 bg-gray-100 p-2 w-[35px] h-[35px]' title=''><Skeleton /></li>
                                <li className='float-left mt-1 mr-3 bg-gray-100 p-2 w-[35px] h-[35px]' title=''><Skeleton /></li>
                                <li className='float-left mt-1 mr-3 bg-gray-100 p-2 w-[35px] h-[35px]' title=''><Skeleton /></li>
                                <li className='float-left mt-1 mr-3 bg-gray-100 p-2 w-[35px] h-[35px]' title=''><Skeleton /></li>
                                <li className='float-left mt-1 mr-3 bg-gray-100 p-2 w-[35px] h-[35px]' title=''><Skeleton /></li>
                            </ul>
                        </div>
                        <div className='float-left w-full lg:w-9/12 md:w-8/12 px-3 pt-8 md:pt-0 font-Nunito_Regular'>
                            <h3 className='md:text-2xl text-xl font-Nunito_SemiBold pb-3'><Skeleton width={400} /></h3>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <ul className='pb-5'>
                                <li className='py-0.5 md:text-base text-sm font-Nunito_Regular text-light_blk'><Skeleton width={200} /></li>
                                <li className='py-0.5 md:text-base text-sm font-Nunito_Regular text-light_blk'><Skeleton width={200} /></li>
                                <li className='py-0.5 md:text-base text-sm font-Nunito_Regular text-light_blk'><Skeleton width={200} /></li>
                            </ul>
                            <Skeleton className='ml-20' width={100} height={30} />
                        </div>
                        <div className='clear-both'></div>
                    </div>
                </div>
            ))
        )
    }
}

export const MenusLoader = ({ count }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <li key={index} className='float-left lg:w-6/12 px-3 my-6 w-full overflow-hidden'>
                    <div className='relative sm:pl-[220px] pl-[170px] border-b border-gray-200 h-[120px] overflow-hidden w-full flex justify-center flex-col'>
                        <div className='absolute left-0 sm:w-[200px] h-[120px] w-[150px] overflow-hidden'><Skeleton height={300} /></div>
                        <h5 className='text-black sm:text-xl text-lg font-Nunito_SemiBold pb-3 pt-3'><Skeleton width={300} /></h5>
                        <Skeleton width={300} />
                    </div>
                </li>
            ))
        )
    }
}

export const GalleryLoader = ({ count }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <div key={index} className="float-left max-w-full sm:w-[294px] sm:h-[294px] h-[200px] relative overflow-hidden">
                    <div>
                        <Skeleton height={250} />
                        <Skeleton height={30} />
                    </div>
                </div>
            ))
        )
    }
}

export const SingleBlogLoader = () => {
    return (
        <div className='float-left w-full flex lg:flex-row flex-col gap-8 items-center pt-10 overflow-hidden'>
            <div className='float-left lg:w-6/12 w-full lg:order-2'>
                <div className='float-left w-full lg:px-2 relative'>
                    <Skeleton height={300} />
                </div>
            </div>
            <div className='float-left lg:w-6/12 w-full lg:order-1 font-Nunito_Regular'>
                <Skeleton width={200} />
                <Skeleton width={400} />
                <span className='w-[70px] h-[2px] float-left bg-burgandy mb-6 mt-2'></span>
                <div className="clear-both"></div>
                <Skeleton />
                <Skeleton />
                <Skeleton width={350} />
                <Skeleton width={150} />
            </div>
        </div>
    )
}

export const BlogListLoader = ({ count }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <div key={index} className='float-left w-full pb-8 overflow-hidden'>
                    <Skeleton height={250} />
                    <div className="clear-both"></div>
                    <Skeleton width={200} />
                    <Skeleton width={400} />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton width={350} />
                    <Skeleton width={150} />
                </div>
            ))
        )
    }
}