import axios from 'axios';
import authContext from 'contexts/auth/authContext';
import { Dropdown, Navbar } from 'flowbite-react';
import fns from 'helpers/functions';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const CustNavbar = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const { t } = useTranslation();
    const cntxAuth = useContext(authContext);
    const user = JSON.parse(localStorage.getItem('user'));
    const [logo, setLogo] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [subdropdownOpen, setSubDropdownOpen] = useState(false);
    const [hideShowClass, setHideShowClass] = useState('hide_menu');
    const [hideShowSubmenuClass, setHideShowSubmenuClass] = useState('hide_submenu');
    const handleDropdownClick = () => {
        setDropdownOpen(!dropdownOpen);
        if (!dropdownOpen) {
            setHideShowClass('show_menu');
        }
        else {
            setHideShowClass('hide_menu');
        }
    }

    const handlesubMenuDropdownClick = () => {
        setSubDropdownOpen(!subdropdownOpen);
        if (!subdropdownOpen) {
            setHideShowSubmenuClass('show_submenu');
        }
        else {
            setHideShowSubmenuClass('hide_submenu');
        }
    }



    useEffect(() => {
        fetchContent();
    }, []);

    const fetchContent = async () => {
        let url = `${API_URL}get-header-footer-data`;
        const header = await axios.get(url).then(function (response) {
            setLogo(response.data.data.header.logo);
        }).catch(function (error) {
            console.log('error : ', error);
        });
    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <>
            <header className="bg-white shadow-lg shadow-black/10 relative md:sticky md:top-0 z-50 py-2.5 clear-both font-Nunito_Bold">
                <img className='w-[90px] float-left absolute bottom-0 right-5 z-10 hidden 2xl:block' src={`${process.env.REACT_APP_FRONT_URL}assets/images/header_shap.svg`} alt="" />
                <div className='xl:container container-fluid m-auto px-4'>
                    <div className='flex lg:flex-wrap justify-between items-center md:flex-col lg:flex-row'>
                        <div className="flex">
                            <Link to={`/`}><img className="md:w-[140px] w-24" src={logo !== '' ? logo : `${process.env.REACT_APP_FRONT_URL}assets/images/logo.jpg`} alt="" /></Link>
                        </div>
                        <div className='navbar'>
                            <button className='toggle_btn' onClick={handleDropdownClick}><i className="fa-solid fa-bars"></i></button>
                            <ul fluid={true} rounded={true} className={`bg-white text-black flex flex-wrap flex-row gap-8 ${hideShowClass}`} show={dropdownOpen}>
                                <li><a className='font-Nunito_Bold !text-black hover:!text-burgandy text-sm leading-10' href="/about-us">About Us</a></li>
                                <li className='submenu relative' onClick={handlesubMenuDropdownClick}><a className='font-Nunito_Bold !text-black hover:!text-burgandy text-sm leading-10' href="/venues">Venues</a>
                                    <ul className={`${hideShowSubmenuClass}`}>
                                        <li><a className='font-Nunito_Bold !text-black hover:!text-burgandy text-sm leading-8' href="/venues?s=featured">Featured Venues</a></li>
                                        <li><a className='font-Nunito_Bold !text-black hover:!text-burgandy text-sm leading-8' href="/venues#Search">Venue Search</a></li>
                                    </ul>
                                </li>
                                <li><a className='font-Nunito_Bold !text-black hover:!text-burgandy text-sm leading-10' href="/our-menus">Our Menus</a></li>
                                <li><a className='font-Nunito_Bold !text-black hover:!text-burgandy text-sm leading-10' href="/weddings">Weddings</a></li>
                                <li><a className='font-Nunito_Bold !text-black hover:!text-burgandy text-sm leading-10' href="/reviews">Reviews</a></li>
                                <li><a className='font-Nunito_Bold !text-black hover:!text-burgandy text-sm leading-10' href="/gallery">Gallery</a></li>
                                <li><a className='font-Nunito_Bold !text-black hover:!text-burgandy text-sm leading-10' href="/takeaway">Takeaways</a></li>
                                <li><a className='font-Nunito_Bold !text-black hover:!text-burgandy text-sm leading-10' href="/blog">Blog</a></li>
                                {/* <li><a className='font-Nunito_Bold !text-black hover:!text-burgandy text-sm leading-10' href="/v1/services">Services</a></li> */}
                                <li><a className='font-Nunito_Bold !text-black hover:!text-burgandy text-sm leading-10' href="/contact-us">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </header>
            <div className="clear-both"></div>
        </>
    )
};

export default CustNavbar;