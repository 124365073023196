import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Breadcrumb } from "flowbite-react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";
import { Pagination } from "components/plugins";
import { VanueLoader } from "components/interfaces/SkeletonLoader";
import { Formik, useFormik } from "formik";
import { VanueInquirySchema } from "modules/validations/Schema";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import constants from "helpers/constants";

const Vanue = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const searchQuery = queryParameters.get("s");
  const API_URL = process.env.REACT_APP_API_URL;
  const [features, setFeatures] = useState([]);
  const [feature_options, setFeatureOptions] = useState([]);
  const [selected_feature, setSelectedFeature] = useState([]);
  const [selected_feature_search, setSelectedFeatureForSearch] = useState([]);
  const [capacities, setCapacities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [venues, setVanues] = useState([]);
  const [search, setSearch] = useState("");
  const [is_featured, setFeatured] = useState(
    searchQuery == "featured" ? "1" : ""
  );
  const [city, setCity] = useState("");
  const [capacity, setCapacity] = useState("");
  const [feature, setFeature] = useState("");
  const [page, setPage] = useState(1);
  const [length, setLength] = useState(5);
  const [content, setContent] = useState("");

  const [total_count, setTotalCount] = useState(0);
  const [last_page, setLastPage] = useState(0);
  const [success_msg, setSuccessMsg] = useState("");
  const [error_msg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const captchaRef = useRef(null);
  const [banner, setBanner] = useState("");

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = async () => {
    let url = `${API_URL}get-header-footer-data`;
    const content = await axios
      .get(url)
      .then(function (response) {
        setContent(response.data.data.footer);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: "smooth",
        });
      }
    }
    window.addEventListener("scroll", () => {
      if (window.matchMedia("(min-width: 767px)").matches) {
        const filterMenuEl = document.getElementById("sticky-area-venue");
        const filterMenuEM = document.getElementById("stick_clearfix");
        const filterMenu = filterMenuEl.getBoundingClientRect();
        const filterBox = filterMenuEM.getBoundingClientRect();

        if (filterMenu.top < 200 || filterMenu.top == 0 || filterBox.top < 80) {
          if (!filterMenuEl.classList.contains("sticky")) {
            filterMenuEl.classList.add("sticky");
          }
        }
      }
    });
    fetchBanner();
    fetchFeatures();
    fetchCapacity();
    fetchLocations();
    fetchVanues();
  }, [
    search,
    is_featured,
    city,
    capacity,
    feature,
    page,
    length,
    selected_feature_search,
  ]);
  useEffect(() => {
    if (searchQuery == "featured") {
      setFeatured("1");
    }
  }, []);

  const fetchBanner = async () => {
    let url = `${API_URL}get-banner/${14}`;
    const cat = await axios
      .get(url)
      .then(function (response) {
        setBanner(response.data?.data?.banner_data);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };

  const fetchFeatures = async () => {
    let url = `${API_URL}get-venue-features`;
    const cat = await axios
      .get(url)
      .then(function (response) {
        setFeatures(response.data.data);
        var stringJ = [];
        response.data.data.forEach(function (value, i) {
          stringJ.push({ value: value.id, label: value.title });
        });
        setFeatureOptions(stringJ);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };
  const fetchCapacity = async () => {
    let url = `${API_URL}get-capacity`;
    const cat = await axios
      .get(url)
      .then(function (response) {
        setCapacities(response.data.data);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };
  const fetchLocations = async () => {
    let url = `${API_URL}get-location`;
    const cat = await axios
      .get(url)
      .then(function (response) {
        setLocations(response.data.data.location);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };
  const fetchVanues = async () => {
    setLoading(true);
    let temp = [];
    if (selected_feature_search.length > 0) {
      selected_feature_search.forEach(function (value, i) {
        temp.push(value.value);
      });
    }
    let url = `${API_URL}get-venues?title=${search}&is_featured=${is_featured}&location=${city}&page=${page}&capacity_id=${capacity}&feature_id=${temp}&length=${length}&is_pagination=1`;
    const gal = await axios
      .get(url)
      .then(function (response) {
        setVanues(response.data.data.venues.data);
        setTotalCount(response.data.data.venues.total);
        setLastPage(response.data.data.venues.last_page);
        setLoading(false);
      })
      .catch(function (error) {
        console.log("error : ", error);
        setLoading(false);
      });
  };
  const setScroll = () => {
    let scroll_box = document.getElementById("scroll_box");
    scroll_box.scrollIntoView({ behavior: "smooth" });
  };

  const initialValues = {
    name: "",
    phone: "",
    email: "",
    venue_name: "",
    feature_id: [],
    website: "",
    address: "",
    message: "",
    captcha: "",
  };
  const {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setErrors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: VanueInquirySchema,
    errors: "",
    onSubmit: async (values, action) => {
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      if (token === null || token === "") {
        setErrors({ captcha: "Please verify captcha!" });
        return;
      } else {
        setErrorMsg("");
      }
      let temp = [];
      if (selected_feature.length > 0) {
        selected_feature.forEach(function (value, i) {
          temp.push(value.value);
        });
      }
      var newvalues = { ...values, feature_id: temp };
      let url = `${API_URL}post-venue-inquiry`;
      const response = await axios
        .post(url, newvalues)
        .then(function (response) {
          if (response.data.status) {
            setSuccessMsg(response.data.message);
            setErrorMsg("");
            action.resetForm();
            setSelectedFeature([]);
          } else {
            setSuccessMsg("");
            setErrorMsg(response.data.message);
          }
        })
        .catch(function (error) {
          console.log("error : ", error);
          setErrorMsg(error);
        });
    },
  });

  return (
    <>
      <div className="bg-white lg:h-[600px] h-[300px] relative">
        <img
          className="h-full w-full object-cover float-left"
          src={
            banner?.image_url !== ""
              ? banner?.image_url
              : `${process.env.REACT_APP_FRONT_URL}assets/images/vanue_banner.jpg`
          }
          alt=""
        />
        <div className="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-white flex items-center font-Nunito_SemiBold">
          <div className="container m-auto px-4">
            <Breadcrumb aria-label="Default breadcrumb example">
              <Link
                to="/"
                className="uppercase font-Nunito_SemiBold text-black hover:text-burgandy"
              >
                Home
              </Link>
              <Breadcrumb.Item className="uppercase">Venues</Breadcrumb.Item>
            </Breadcrumb>
            {banner !== null && typeof banner !== "undefined" ? (
              <>
                {parse(
                  banner.short_description ? banner.short_description : ""
                )}
              </>
            ) : (
              <>
                <h1 className="text-[24px] md:text-[36px] leading-[32px] md:leading-[46px] font-Nunito_Bold text-burgandy py-4">
                  There’s
                  <br />a Venue For Every Occasion
                </h1>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="clear-both"></div>
      {features !== "" &&
        typeof features !== "undefined" &&
        features.length > 0 && (
          <div
            id="sticky-area-venue"
            className="bg-gray-100 float-left w-full md:py-10 md:pb-6 py-10 top-[93px] z-10 transition-all"
          >
            <div className="container m-auto px-4">
              <div className="text-center">
                <h2 className="lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold">
                  Features
                </h2>
                <span className="w-[70px] h-[2px] block m-auto bg-burgandy mb-4 mt-2"></span>
              </div>
              <div className="text-center font-Nunito_Regular">
                <div className="-mx-2" id="stick_clearfix">
                  {features.map((feature, index) => (
                    <div
                      key={index}
                      className="float-left lg:w-2/12 md:w-4/12 w-6/12 py-5 px-2"
                    >
                      <img
                        className="w-[32px] block m-auto mb-2"
                        src={
                          feature?.image_url
                            ? feature?.image_url
                            : `${process.env.REACT_APP_FRONT_URL}assets/images/venue_feature_icon1.svg`
                        }
                        alt=""
                      />
                      <h3 className="sm:text-base text-sm text-black tracking-wider">
                        {feature?.title}
                      </h3>
                    </div>
                  ))}
                  <div className="clear-both"></div>
                </div>
              </div>
              <div className="clear-both"></div>
            </div>
          </div>
        )}
      <div className="clear-both"></div>
      <div className="bg-white float-left w-full md:py-24 py-12" id="Search">
        <div id="scroll_box" className="container m-auto px-4">
          <div className="float-left w-full font-Nunito_Regular">
            <h2 className="lg:max-w-[500px] lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold">
              Venues
            </h2>
            <span className="w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2"></span>
          </div>
          <div className="float-left w-full font-Nunito_Regular md:pb-10 pb-2 relative">
            <img
              class="w-[60px] float-left absolute -top-20 right-5"
              src={`${process.env.REACT_APP_FRONT_URL}assets/images/services_shap2.svg`}
              alt=""
            />
            <div className="-mx-2 relative z-[1]">
              <div className="float-left lg:w-3/12 w-6/12 my-2 lg:my-0 px-2 relative">
                <input
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  type="text"
                  placeholder="Search Venues..."
                  className="font-Nunito_Regular text-base block w-full border-0 bg-gray-100 placeholder:text-[#000] text-black focus:outline-none rounded-full py-5 px-5 pr-14 "
                  id="searchInput"
                  ref={captchaRef}
                />
                <i className="fa-solid fa-magnifying-glass absolute right-7 top-[24px] text-gray-400"></i>
              </div>
              <div
                className="float-left lg:w-2/12 w-6/12 my-2 lg:my-0 px-2"
                id="select1"
              >
                <select
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  name=""
                  className="font-Nunito_Regular text-base block w-full border-0 bg-gray-100 placeholder:text-[#696969] text-black focus:outline-none rounded-full py-5 px-5"
                >
                  <option value="">Location</option>
                  {locations !== "" &&
                    typeof locations !== "undefined" &&
                    Object.entries(locations).map(([id, value], index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                </select>
              </div>
              <div
                className="float-left lg:w-2/12 w-6/12 my-2 lg:my-0 px-2"
                id="select2"
              >
                <select
                  onChange={(e) => {
                    setCapacity(e.target.value);
                  }}
                  name=""
                  className="font-Nunito_Regular text-base block w-full border-0 bg-gray-100 placeholder:text-[#696969] text-black focus:outline-none rounded-full py-5 px-5"
                >
                  <option value="">Capacity</option>
                  {capacities !== "" &&
                    typeof capacities !== "undefined" &&
                    Object.entries(capacities).map(([id, value], index) => (
                      <option key={index} value={id}>
                        {value}
                      </option>
                    ))}
                </select>
              </div>
              {/* <div className='float-left lg:w-2/12 w-6/12 my-2 lg:my-0 px-2' id="select3">
                                <select onChange={(e) => { updateFeature(e.target.value) }} name="" className="font-Nunito_Regular text-base block w-full border-0 bg-gray-100 placeholder:text-[#696969] text-black focus:outline-none rounded-full py-5 px-5">
                                    <option value=''>Features</option>
                                    {
                                        features.map((feature, index) =>
                                            <option key={index} value={feature?.id}>{feature?.title}</option>
                                        )
                                    }
                                </select>
                            </div> */}
              <div
                className="float-left lg:w-2/12 w-6/12 my-2 lg:my-0 px-2"
                id="select3"
              >
                <select
                  onChange={(e) => {
                    setFeatured(e.target.value);
                  }}
                  name=""
                  className="font-Nunito_Regular text-base block w-full border-0 bg-gray-100 placeholder:text-[#696969] text-black focus:outline-none rounded-full py-5 px-5"
                  value={is_featured}
                >
                  <option value="">All</option>
                  <option value="1">Featured</option>
                </select>
              </div>
              <div className="float-left lg:w-3/12 w-6/12 my-2 lg:my-0 px-2 relative">
                <Select
                  placeholder="Select Feature"
                  className="font-Nunito_Regular text-base block w-full border-0 bg-gray-100 placeholder:text-[#696969] text-black focus:outline-none rounded-full py-3 px-5 top_bar_select"
                  options={feature_options}
                  value={selected_feature_search}
                  onChange={(val) => {
                    setSelectedFeatureForSearch(val);
                  }}
                  isMulti
                />
              </div>
              <div className="clear-both"></div>
            </div>
          </div>
          <div className="float-left w-full font-Nunito_Regular">
            {loading ? (
              <>
                <VanueLoader count={3} />
              </>
            ) : (
              <>
                {venues !== "" &&
                venues.length > 0 &&
                typeof venues !== "undefined" ? (
                  <>
                    {venues.map((venue, index) => (
                      <div
                        key={index}
                        className="float-left w-full border-b border-gray-200 py-10"
                      >
                        <div className="-mx-3">
                          <div className="float-left w-full lg:w-3/12 md:w-4/12 px-3">
                            <div className="float-left w-full h-[240px] rounded-2xl overflow-hidden mb-5">
                              <img
                                className="w-full float-left h-full object-cover"
                                src={
                                  venue?.image_url
                                    ? venue?.image_url
                                    : `${process.env.REACT_APP_FRONT_URL}assets/images/venue_img1.jpg`
                                }
                                alt=""
                              />
                            </div>
                            <div className="clear-both"></div>
                            <ul className="w-full float-left pt-6">
                              {venue?.selected_feature !== null &&
                                venue?.selected_feature.length > 0 && (
                                  <>
                                    {venue.selected_feature.map(
                                      (feature, key) => (
                                        <li
                                          key={key}
                                          className="float-left mt-1 mr-3 bg-gray-100 p-2 w-[35px] h-[35px]"
                                          title={feature?.venue_feature.title}
                                        >
                                          <img
                                            className="float-left"
                                            src={
                                              feature?.venue_feature?.image_url
                                                ? feature?.venue_feature
                                                    ?.image_url
                                                : `${process.env.REACT_APP_FRONT_URL}assets/images/venue_feature_icon4.svg`
                                            }
                                            alt=""
                                          />
                                        </li>
                                      )
                                    )}
                                  </>
                                )}
                            </ul>
                          </div>
                          <div className="float-left w-full lg:w-9/12 md:w-8/12 px-3 pt-8 md:pt-0 font-Nunito_Regular">
                            <h3 className="md:text-2xl text-xl font-Nunito_SemiBold pb-3">
                              {venue?.title}
                            </h3>
                            {parse(
                              venue?.description ? venue?.description : ""
                            )}
                            <ul className="py-5">
                              <li className="py-0.5 md:text-base text-sm font-Nunito_Regular text-light_blk">
                                Location :{" "}
                                <strong className="font-Nunito_SemiBold text-black">
                                  {venue?.location
                                    ? venue?.location
                                    : "Not available"}
                                </strong>
                              </li>
                              <li className="py-0.5 md:text-base text-sm font-Nunito_Regular text-light_blk">
                                Capacity :{" "}
                                <strong className="font-Nunito_SemiBold text-black">
                                  {venue?.capacity
                                    ? venue?.capacity
                                    : "Not available"}
                                </strong>
                              </li>
                              {venue?.room_capacity !== null &&
                                venue?.room_capacity.length > 0 && (
                                  <>
                                    {venue.room_capacity.map((room, key) => (
                                      <li
                                        key={key}
                                        className="px-0.5 py-0.5 md:text-base text-sm font-Nunito_Regular text-light_blk"
                                      >
                                        Capacity Room {key + 1} :{" "}
                                        <strong className="font-Nunito_SemiBold text-black">
                                          {room.capacity}
                                        </strong>
                                      </li>
                                    ))}
                                  </>
                                )}
                            </ul>
                            <a
                              href={`https://maps.google.com/maps?q=${venue?.full_address}`}
                              target="_blank"
                              className="md:text-base text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_Bold"
                            >
                              <i className="fa-solid fa-minus mr-2"></i> VIEW
                              MAP
                            </a>
                          </div>
                          <div className="clear-both"></div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <p className="text-center text-lg font-Nunito_SemiBold text-black">
                      No venue found.
                    </p>
                  </>
                )}
              </>
            )}
          </div>
          <div className="float-left w-full font-Nunito_Regular text-center pt-14">
            {last_page > 1 && (
              <Pagination
                totalCount={total_count}
                perPageRecord={length}
                handlePageClick={(data) => {
                  setPage(data.selected + 1);
                  setScroll();
                }}
              />
            )}
            {last_page > 1 && (
              <p>Page {page} of {last_page}</p>
            )}
          </div>
          <div className="clear-both"></div>
        </div>
      </div>
      <div className="clear-both"></div>
      <div className="bg-gray-100 float-left w-full md:py-24 py-12">
        <div className="container m-auto px-4 relative">
          <img
            class="w-[120px] float-left absolute md:-top-[220px] -top-[140px] right-5"
            src={`${process.env.REACT_APP_FRONT_URL}assets/images/venue_form_shap1.svg`}
            alt=""
          />
          <img
            class="w-[60px] float-left absolute -bottom-10 left-5"
            src={`${process.env.REACT_APP_FRONT_URL}assets/images/venue_form_shap2.svg`}
            alt=""
          />
          <div className="float-left w-full font-Nunito_Regular relative z-[1]">
            <h2 className="lg:max-w-[500px] lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold">
              List Your Venue Here
            </h2>
            <span className="w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2"></span>
          </div>
          <div className="float-left w-full font-Nunito_Regular pb-2">
            <div className="-mx-2 lg:flex flex-wrap">
              {/* <form onSubmit={handleSubmit} className='-mx-2 lg:flex flex-wrap'>
                                <div className='float-left lg:w-4/12 sm:w-6/12 w-full px-2 py-3 relative'>
                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        placeholder="Your Name"
                                        className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-4 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />
                                    {errors.name && touched.name ? <small className='text-red-600'>{errors.name}</small> : null}
                                </div>
                                <div className='float-left lg:w-4/12 sm:w-6/12 w-full px-2 py-3 relative'>
                                    <input
                                        id="phone"
                                        name="phone"
                                        type="text"
                                        placeholder="Contact Number  e.g +91123456"
                                        className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-4 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone}
                                    />
                                    {errors.phone && touched.phone ? <small className='text-red-600'>{errors.phone}</small> : null}
                                </div>
                                <div className='float-left lg:w-4/12 sm:w-6/12 w-full px-2 py-3 relative'>
                                    <input
                                        id="email"
                                        name="email"
                                        type="text"
                                        placeholder="E-mail"
                                        className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-4 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                    {errors.email && touched.email ? <small className='text-red-600'>{errors.email}</small> : null}
                                </div>
                                <div className='float-left lg:w-4/12 sm:w-6/12 w-full px-2 py-3 relative'>
                                    <input
                                        id="venue_name"
                                        name="venue_name"
                                        type="text"
                                        placeholder="Venue Name"
                                        className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-4 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.venue_name}
                                    />
                                    {errors.venue_name && touched.venue_name ? <small className='text-red-600'>{errors.venue_name}</small> : null}
                                </div>
                                <div className='float-left lg:w-4/12 sm:w-6/12 w-full px-2 py-3 venue_type_select' id="select3">
                                    <Select
                                        name='feature_id'
                                        id='feature_id'
                                        className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-2 px-2 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                                        options={feature_options}
                                        value={selected_feature}
                                        onChange={(val) => { setSelectedFeature(val) }}
                                        isMulti
                                    />
                                    {errors.feature_id && touched.feature_id ? <small className='text-red-600'>{errors.feature_id}</small> : null}
                                </div>
                                <div className='float-left lg:w-4/12 sm:w-6/12 w-full px-2 py-3 relative'>
                                    <input
                                        id="website"
                                        name="website"
                                        type="text"
                                        placeholder="Website"
                                        className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-4 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.website}
                                    />
                                    {errors.website && touched.website ? <small className='text-red-600'>{errors.website}</small> : null}
                                </div>
                                <div className='float-left lg:w-6/12 sm:w-6/12 w-full px-2 py-3 relative'>
                                    <textarea
                                        id="address"
                                        name="address"
                                        type="text"
                                        placeholder="Venue Address"
                                        className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-4 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none resize-none h-[110px]"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.address}
                                    >
                                    </textarea>
                                    {errors.address && touched.address ? <small className='text-red-600'>{errors.address}</small> : null}
                                </div>
                                <div className='float-left lg:w-6/12 sm:w-6/12 w-full px-2 py-3 relative'>
                                    <textarea
                                        id="message"
                                        name="message"
                                        type="text"
                                        placeholder="Massage"
                                        className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-4 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none resize-none h-[110px]"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.message}
                                    >
                                    </textarea>
                                    {errors.message && touched.message ? <small className='text-red-600'>{errors.message}</small> : null}
                                </div>
                                <div className='float-left lg:w-6/12 sm:w-6/12 w-full px-2 py-3 relative overflow-hidden'>
                                    <ReCAPTCHA id="captcha" name="captcha" sitekey={constants.RECAPTCHA_SITE_KEY} ref={captchaRef} />
                                    {errors.captcha && touched.captcha ? <small className='text-red-600'>{errors.captcha}</small> : null}
                                </div>
                                {
                                    error_msg !== ''
                                    &&
                                    <p className='text-red-600'>{error_msg}</p>
                                }
                                <div className='float-left w-full my-2 lg:my-0 md:pt-10 pt-5 py-3 pb-0 text-center'>
                                    <button type='submit' className='md:text-base text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_SemiBold'><i className="fa-solid fa-minus mr-2"></i> Submit Venue</button>
                                </div>
                                <div className='clear-both'></div>
                            </form> */}

              <div className="float-left w-full mb-1">
                <h5 className="text-xl font-Nunito_Regular leading-8 text-light_blk">
                  If you are Interested in{" "}
                  <strong className="font-Nunito_SemiBold">
                    Listing a Venue
                  </strong>{" "}
                  ,Please
                  <Link
                    to={`/book-a-call`}
                    className="font-Nunito_SemiBold hover:text-black text-burgandy"
                  >
                    {" "}
                    book a call
                  </Link>
                  <br />
                  OR You can Call us on{" "}
                  <a
                    href={`tel:${content?.phone}`}
                    className="font-Nunito_SemiBold hover:text-black text-burgandy"
                  >
                    {content?.phone}
                  </a>{" "}
                  or E-mail{" "}
                  <a
                    href={`mailto:${content?.email}`}
                    className="font-Nunito_SemiBold hover:text-black text-burgandy"
                  >
                    {content?.email}
                  </a>{" "}
                </h5>
              </div>
            </div>
            {success_msg !== "" && typeof success_msg !== "undefined" ? (
              <p className="text-green-600 text-lg pt-5 mb-0 font-Nunito_SemiBold text-center">
                {success_msg}
              </p>
            ) : null}
          </div>
          <div className="clear-both"></div>
        </div>
      </div>
    </>
  );
};

export default Vanue;
