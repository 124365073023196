import { useState } from "react";
import AuthContext from "./authContext";

const AuthState = (props) => {
    var loclVar = false;
    if (localStorage.getItem('_token') && typeof localStorage.getItem('_token') !== 'undefined') {
        loclVar = true;
    }

    const [isLogged, setLoggedIn] = useState(loclVar);

    const updateLogin = (flag) => {
        setLoggedIn(flag);
    }

    return (
        <AuthContext.Provider value={{ isLogged, updateLogin }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthState;