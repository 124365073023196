import * as Yup from "yup";
const today =
  new Date().getFullYear() +
  "-" +
  ("0" + (new Date().getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + new Date().getDate()).slice(-2);
const URL =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
export const VanueInquirySchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").required("Please enter your name!"),
  phone: Yup.number("Please enter number only!")
    .required("Please enter contact number!")
    .typeError("Please enter number only!"),
  email: Yup.string().email("Invalid email").required("Please enter email!"),
  venue_name: Yup.string()
    .min(2, "Too Short!")
    .required("Please enter vanue name!"),
  feature_id: Yup.array().required("Please select vanue type!"),
  website: Yup.string().notRequired().matches(URL, "Please enter valid url!"),
  address: Yup.string().required("Please enter address!"),
  message: Yup.string().required("Please enter message!"),
});

export const ContactUsSchema = Yup.object().shape({
  category_id: Yup.string().required("Please select inquiry type!"),
  title: Yup.string().required("Please select title!"),
  name: Yup.string().min(2, "Too Short!").required("Please enter your name!"),
  phone: Yup.number("Please enter number only!")
    .required("Please enter contact number!")
    .typeError("Please enter number only!"),
  email: Yup.string().email("Invalid email").required("Please enter email!"),
  message: Yup.string().required("Please enter message!"),
  is_mailing_list: Yup.boolean()
    .required("Please choose!")
    .typeError("Please choose!"),
});

export const GeneralEnquirySchema = Yup.object().shape({
  // category_id: Yup.string().required("Please select inquiry type!"),
  title: Yup.string().required("Please select title!"),
  name: Yup.string().min(2, "Too Short!").required("Please enter your name!"),
  phone: Yup.number("Please enter number!")
    .required("Please enter contact number!")
    .typeError("Please enter number!")
    .min(100000, "Phone number must be at least 6 digits")
    .max(99999999999, "Phone number must be 11 digits"),
  email: Yup.string().email("Invalid email").required("Please enter email!"),
  message: Yup.string().required("Please enter message!"),
  is_mailing_list: Yup.boolean()
    .required("Please choose!")
    .typeError("Please choose!"),
});

export const WeddingInquirySchema = Yup.object().shape({
  title: Yup.string().required("Please select title!"),
  name: Yup.string().required("Please enter your name!").min(2, "Too Short!"),
  phone: Yup.number("Please enter number!")
    .required("Please enter contact number!")
    .typeError("Please enter number!")
    .min(100000, "Phone number must be at least 6 digits")
    .max(99999999999, "Phone number must be 11 digits"),
  email: Yup.string().required("Please enter email!").email("Invalid email"),
  is_mailing_list: Yup.boolean()
    .required("Please choose!")
    .typeError("Please choose!"),

  events: Yup.array().of(
    Yup.object().shape({
      type_of_event_id: Yup.array()
        .required("Please select type of event!")
        .min(1, "Please select at least one type of event!"),
      event_date: Yup.date()
        .required("Please select an approximate date of event!")
        .min(today, "min date")
        .typeError("Please select an approximate date of event!"),
      venue_id: Yup.string().required("Please enter venue!"),
      // capacity_id: Yup.string().required("Please select size of party!"),
      number_of_guests: Yup.string().required("Please enter number of guests!"),
      start_time: Yup.string().required("Please select start time!"),
      end_time: Yup.string().required("Please select end time!"),
      type_of_food_id: Yup.array()
        .required("Please select food type!")
        .min(1, "Please select at least one type of food!"),
      // is_ceremony_reception: Yup.boolean()
      //   .required("Please choose!")
      //   .typeError("Please choose!"),
      is_veg: Yup.boolean()
        .required("Please choose!")
        .typeError("Please choose!"),
      is_alchohol: Yup.boolean()
        .required("Please choose!")
        .typeError("Please choose!"),
      message: Yup.string().required("Please enter message!"),
    })
  ),
});

export const EventInquirySchema = Yup.object().shape({
  title: Yup.string().required("Please select title!"),
  name: Yup.string().required("Please enter your name!").min(2, "Too Short!"),
  phone: Yup.number("Please enter number!")
    .required("Please enter contact number!")
    .typeError("Please enter number!")
    .min(100000, "Phone number must be at least 6 digits")
    .max(99999999999, "Phone number must be 11 digits"),
  email: Yup.string().required("Please enter email!").email("Invalid email"),
  is_mailing_list: Yup.boolean()
    .required("Please choose!")
    .typeError("Please choose!"),

  type_of_event: Yup.array()
    .required("Please select type of event!")
    .min(1, "Please select at least one type of event!"),
  date: Yup.date()
    .required("Please select an approximate date of event!")
    .min(today, "min date")
    .typeError("Please select an approximate date of event!"),
  venue: Yup.string().required("Please enter venue!"),
  number_of_guests: Yup.string().required("Please enter number of guests!"),
  start_time: Yup.string().required("Please select start time!"),
  end_time: Yup.string().required("Please select end time!"),
  type_of_food: Yup.array()
    .required("Please select food type!")
    .min(1, "Please select at least one type of food!"),
  // is_ceremony_reception: Yup.boolean()
  //   .required("Please choose!")
  //   .typeError("Please choose!"),
  is_veg: Yup.boolean().required("Please choose!").typeError("Please choose!"),
  is_alchohol: Yup.boolean()
    .required("Please choose!")
    .typeError("Please choose!"),
  message: Yup.string().required("Please enter message!"),
});

export const FoodInquirySchema = Yup.object().shape({
  title: Yup.string().required("Please select title!"),
  name: Yup.string().required("Please enter your name!").min(2, "Too Short!"),
  phone: Yup.number("Please enter number!")
    .required("Please enter contact number!")
    .typeError("Please enter number!")
    .min(100000, "Phone number must be at least 6 digits")
    .max(99999999999, "Phone number must be 11 digits"),
  email: Yup.string().required("Please enter email!").email("Invalid email"),
  is_mailing_list: Yup.boolean()
    .required("Please choose!")
    .typeError("Please choose!"),

  event_date: Yup.date()
    .required("Please select an approximate date of event!")
    .min(today, "min date")
    .typeError("Please select an approximate date of event!"),
  no_of_people: Yup.string()
    .required("Please add the number of people requiring catering!")
    .matches(/^[1-9]\d*$/, "Please enter a positive integer greater than 0")
    .test("max-people", "Number of people should be less than 500", (value) => {
      if (!value) return true; // Allow empty value (handled by required)
      return parseInt(value, 10) < 500;
    }),

  message: Yup.string().required("Please enter about your order!"),
});

export const NewsletterSchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").required("Please enter your name!"),
  email: Yup.string().email("Invalid email").required("Please enter email!"),
});
