import React, { useEffect, useState, useTransition, useRef } from "react";
import { Breadcrumb, Label, Radio } from "flowbite-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import { Link } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import { ContactUsSchema } from "modules/validations/Schema";
import constants from "../../helpers/constants";
import WeddingInquiry from "./forms/WeddingInquiry";
import ReCAPTCHA from "react-google-recaptcha";
import HomeEventInquiry from "./forms/HomeEventInquiry";

const TakewayInquiry = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [startDate, setStartDate] = useState(new Date());
  const [value, onChange] = useState("10:00");
  const [success_msg, setSuccessMsg] = useState("");
  const [error_msg, setErrorMsg] = useState("");
  const { t } = useTransition();
  const [content, setContent] = useState("");
  const [categories, setCategories] = useState("");
  const [titles, setTitles] = useState("");
  const [proccessing, setProccessing] = useState(false);
  const [form_view, setFormView] = useState(0);
  const captchaRef = useRef(null);

  useEffect(() => {
    fetchContent();
    fetchFields();
  }, []);

  const fetchContent = async () => {
    let url = `${API_URL}get-header-footer-data`;
    const content = await axios
      .get(url)
      .then(function (response) {
        setContent(response.data.data.footer);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };
  const fetchFields = async () => {
    let url = `${API_URL}get-contactus`;
    const cat = await axios
      .get(url)
      .then(function (response) {
        setCategories(response.data.data.categories);
        setTitles(response.data.data.title);
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  };

  const initialValues = {
    category_id: "",
    title: "",
    name: "",
    phone: "",
    email: "",
    message: "",
    is_mailing_list: "",
    captcha: "",
  };
  const {
    errors,
    values,
    touched,
    isMulti,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setErrors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ContactUsSchema,
    errors: "",
    onSubmit: async (values, action) => {
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      if (token === null || token === "") {
        setErrors({ captcha: "Please verify captcha!" });
        return;
      } else {
        setErrorMsg("");
      }
      setProccessing(true);
      let url = `${API_URL}post-contact-us`;
      const response = await axios
        .post(url, values)
        .then(function (response) {
          if (response.data.status) {
            setSuccessMsg(response.data.message);
            setErrorMsg("");
            action.resetForm();
          } else {
            setSuccessMsg("");
            setErrorMsg(response.data.message);
          }
          setProccessing(false);
        })
        .catch(function (error) {
          console.log("error : ", error);
          setProccessing(false);
        });
    },
  });

  return (
    <>
      <div className="bg-white lg:h-[600px] h-[300px] relative">
        <img
          className="h-full w-full object-cover float-left"
          src={`${process.env.REACT_APP_FRONT_URL}assets/images/gallery_banner.jpg`}
          alt=""
        />
        <div className="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-white flex items-center font-Nunito_SemiBold">
          <div className="container m-auto px-4">
            <Breadcrumb aria-label="Default breadcrumb example">
              <Link
                to="/"
                className="uppercase font-Nunito_SemiBold text-black hover:text-burgandy"
              >
                Home
              </Link>
              <Breadcrumb.Item className="uppercase">
                TAKEAWAY ENQUIRY
              </Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-[24px] md:text-[36px] leading-[32px] md:leading-[46px] font-Nunito_Bold text-burgandy py-4">
              Get in Touch
              <br />
              With us Today
            </h1>
          </div>
        </div>
      </div>

      <div className="clear-both"></div>
      <div className="bg-gray-100 float-left w-full md:py-24 py-12 overflow-hidden">
        <div className="container m-auto px-4 relative">
          <img
            class="sm:w-[170px] w-[140px] float-left absolute md:-bottom-[95px] -bottom-[45px] xl:-left-10 left-2"
            src={`${process.env.REACT_APP_FRONT_URL}assets/images/banner_shape.svg`}
            alt=""
          />
          <div className="float-left w-full">
            <h2 className="lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold">
              We’d love to help you
            </h2>
            <span className="w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2"></span>
            <div className="clear-both"></div>
            <h5 className="text-xl font-Nunito_Regular leading-8 text-light_blk">
              If you are Interested in Raising a{" "}
              <strong className="font-Nunito_SemiBold">
                {" "}
                Home event enquiry
              </strong>{" "}
              ,Please
              <Link
                to={`/book-a-call`}
                className="font-Nunito_SemiBold hover:text-black text-burgandy"
              >
                {" "}
                book a call
              </Link>
              <br />
              OR You can Call us on{" "}
              <a
                href={`tel:${content?.phone}`}
                className="font-Nunito_SemiBold hover:text-black text-burgandy"
              >
                {content?.phone}
              </a>{" "}
              or E-mail{" "}
              <a
                href={`mailto:${content?.email}`}
                className="font-Nunito_SemiBold hover:text-black text-burgandy"
              >
                {content?.email}
              </a>{" "}
            </h5>
          </div>
          <div className="float-left w-full pt-10">
            <ul className="flex sm:flex-row flex-wrap md:gap-14 gap-6 font-Nunito_Regular md:text-base text-sm py-5 mb-5">
              {/* <li>
                <button
                  type="button"
                  className={`${
                    form_view === 0
                      ? "text-burgandy border-burgandy"
                      : "text-[#696969] hover:text-burgandy border-transparent hover:border-burgandy "
                  } border-b pb-1`}
                  onClick={() => {
                    setFormView(0);
                  }}
                >
                  Contact Us
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`${
                    form_view === 1
                      ? "text-burgandy border-burgandy"
                      : "text-[#696969] hover:text-burgandy border-transparent hover:border-burgandy "
                  } border-b pb-1`}
                  onClick={() => {
                    setFormView(1);
                  }}
                >
                  Wedding Enquiry
                </button>
              </li> */}
              {/* <li>
                <button
                  type="button"
                  className={`${
                    form_view === 0
                      ? "text-burgandy border-burgandy"
                      : "text-[#696969] hover:text-burgandy border-transparent hover:border-burgandy "
                  } border-b pb-1`}
                  onClick={() => {
                    setFormView(0);
                  }}
                >
                  Home Event Enquiry
                </button>
              </li> */}
            </ul>
            <div className="float-left w-full">
              {/* start Contact Form */}
              {/* <div className={`${form_view === 0 ? "block" : "hidden"}`}>
                <div className="-mx-5 relative">
                  <form onSubmit={handleSubmit}>
                    <div className="float-left w-full md:w-6/12 px-5">
                      <div className="float-left w-full my-2">
                        <select
                          id="category_id"
                          name="category_id"
                          className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.category_id}
                        >
                          <option value="" className="text-gray-400">
                            I am Enquiring About
                          </option>
                          {categories !== "" &&
                            typeof categories !== "undefined" &&
                            Object.entries(categories).map(
                              ([id, value], index) => (
                                <option key={index} value={id}>
                                  {value}
                                </option>
                              )
                            )}
                        </select>
                        {errors.category_id && touched.category_id ? (
                          <small className="text-red-600">
                            {errors.category_id}
                          </small>
                        ) : null}
                      </div>
                      <div className="float-left w-full my-2">
                        <select
                          id="title"
                          name="title"
                          className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                        >
                          <option value="" className="text-gray-400">
                            Title
                          </option>
                          {titles !== "" &&
                            typeof titles !== "undefined" &&
                            Object.entries(titles).map(([id, value], index) => (
                              <option key={index} value={id}>
                                {value}
                              </option>
                            ))}
                        </select>
                        {errors.title && touched.title ? (
                          <small className="text-red-600">{errors.title}</small>
                        ) : null}
                      </div>
                      <div className="float-left w-full my-2">
                        <input
                          id="name"
                          name="name"
                          type="text"
                          placeholder="Your Name"
                          className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                        {errors.name && touched.name ? (
                          <small className="text-red-600">{errors.name}</small>
                        ) : null}
                      </div>
                      <div className="float-left w-full my-2">
                        <input
                          id="phone"
                          name="phone"
                          type="text"
                          placeholder="Contact Number e.g +91123456"
                          className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                        />
                        {errors.phone && touched.phone ? (
                          <small className="text-red-600">{errors.phone}</small>
                        ) : null}
                      </div>
                      <div className="float-left w-full my-2">
                        <input
                          id="email"
                          name="email"
                          type="text"
                          placeholder="E-mail"
                          className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {errors.email && touched.email ? (
                          <small className="text-red-600">{errors.email}</small>
                        ) : null}
                      </div>
                    </div>
                    <div className="float-right w-full md:w-6/12 px-5 relative">
                      <img
                        class="w-[140px] float-left absolute -top-10 -right-10 xl:block hidden"
                        src={`${process.env.REACT_APP_FRONT_URL}assets/images/aboutimg_shap-01.svg`}
                        alt=""
                      />
                      <div className="float-left w-full my-2 relative">
                        <textarea
                          id="message"
                          name="message"
                          type="text"
                          placeholder="Add a Bit More Enquiry in Details..."
                          className="font-Nunito_SemiBold text-base block w-full border-0 bg-white placeholder:text-gray-400 text-black focus:border-0 rounded-3xl py-3.5 px-5 placeholder:text-blue/50 focus:border-1 focus:placeholder:text-white focus:outline-none resize-none h-[324px]"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.message}
                        ></textarea>
                        {errors.message && touched.message ? (
                          <small className="text-red-600">
                            {errors.message}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    <div className="float-left w-full md:w-6/12 px-5 my-2">
                      <fieldset
                        className="flex py-4 text-base text-black font-Nunito_Regular justify-between items-start flex-row gap-4"
                        id="radio1"
                      >
                        <div className="flex items-start gap-5">
                          <legend className="font-Nunito_SemiBold">
                            Would you like to be added to our mailing list?
                          </legend>
                        </div>
                        <div className="flex items-end gap-5">
                          <div className="flex items-center gap-2">
                            <Radio
                              id="is_mailing_list"
                              name="is_mailing_list"
                              value="1"
                              onChange={() =>
                                setFieldValue("is_mailing_list", "1")
                              }
                              onBlur={handleBlur}
                              checked="checked"
                            />
                            <Label htmlFor="is_mailing_list-yes">Yes</Label>
                          </div>
                          <div className="flex items-center gap-2">
                            <Radio
                              id="is_mailing_listNo"
                              name="is_mailing_list"
                              value="0"
                              onChange={() =>
                                setFieldValue("is_mailing_list", "0")
                              }
                              onBlur={handleBlur}
                            />
                            <Label htmlFor="is_mailing_listNo">No</Label>
                          </div>
                        </div>
                      </fieldset>
                      {errors.is_mailing_list && touched.is_mailing_list ? (
                        <small className="text-red-600">
                          {errors.is_mailing_list}
                        </small>
                      ) : null}
                    </div>
                    <div className="float-left w-full px-5 py-3 relative overflow-hidden">
                      <ReCAPTCHA
                        id="captcha"
                        name="captcha"
                        sitekey={constants.RECAPTCHA_SITE_KEY}
                        ref={captchaRef}
                      />
                      {errors.captcha && touched.captcha ? (
                        <small className="text-red-600">{errors.captcha}</small>
                      ) : null}
                    </div>
                    <div className="float-left w-full my-2 lg:my-0 md:pt-10 pt-5 py-3 pb-0 text-center">
                      {proccessing && (
                        <div className="absolute bg-gray-100/60 left-0 right-0 top-0 bottom-0 flex items-center justify-center">
                          <img src={constants.FORM_LOADER} alt="" width={50} />
                        </div>
                      )}
                      <button
                        type="submit"
                        className="md:text-base text-sm text-burgandy hover:text-black uppercase inline-block font-Nunito_SemiBold"
                      >
                        <i className="fa-solid fa-minus mr-2"></i> Send Enquiry
                      </button>
                    </div>
                  </form>
                  <div className="clear-both"></div>
                  {success_msg !== "" && typeof success_msg !== "undefined" ? (
                    <p className="text-green-600 text-lg pt-5 mb-0 font-Nunito_SemiBold text-center">
                      {success_msg}
                    </p>
                  ) : null}
                  {error_msg !== "" && typeof error_msg !== "undefined" ? (
                    <p className="text-green-600 text-lg pt-5 mb-0 font-Nunito_SemiBold text-center">
                      {error_msg}
                    </p>
                  ) : null}
                </div>
                <div className="clear-both"></div>
              </div> */}
              {/* End Contact Form */}

              {/* Start Wedding Enquiry Form */}
              {/* <div className={`${form_view === 1 ? "block" : "hidden"}`}>
                <WeddingInquiry />
              </div> */}
              {/* End Wedding Enquiry Form */}
              {/* Start Home Enquiry Form */}
              {/* <div className={`${form_view === 0 ? "block" : "hidden"}`}>
                <HomeEventInquiry />
              </div> */}
              {/* End Home Enquiry Form */}
            </div>
          </div>
          <div className="clear-both"></div>
        </div>
      </div>

      <div className="clear-both"></div>
      {/* <div className="bg-white float-left w-full md:pt-24 pt-12">
        <div className="container m-auto px-4 relative">
          <img
            class="md:w-[140px] w-[100px] float-left absolute md:-top-[135px] -top-[85px] right-2"
            src={`${process.env.REACT_APP_FRONT_URL}assets/images/contact_shap.svg`}
            alt=""
          />
          <div className="float-left w-full">
            <h2 className="lg:text-[28px] text-[22px] text-black font-Nunito_SemiBold">
              Where We Work
            </h2>
            <span className="w-[70px] h-[2px] float-left bg-burgandy mb-8 mt-2"></span>
            <div className="clear-both"></div>
          </div>
        </div>
        <div className="float-left w-full relative min-h-[400px]">
          <iframe
            className="absolute left-0 top-0 right-0 bottom-0 w-full h-full"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4965.951923965643!2d-0.39804!3d51.513657!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb4747a94010153e9!2sPreeti%20Catering!5e0!3m2!1sen!2sus!4v1672825653377!5m2!1sen!2sus"
          />
        </div>
      </div> */}
    </>
  );
};

export default TakewayInquiry;
