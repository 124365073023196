import { default as CustRouter } from './routes/Router';
import { Footer, CustNavbar } from 'modules/fragments';
import { Toaster } from 'react-hot-toast';
import AuthState from 'contexts/auth/authState';

export default function App() {

    return (
        <div>
            <AuthState>
                <CustNavbar />
                <CustRouter />
                <Footer />
                <Toaster position="top-right" />
            </AuthState>
        </div>
    );
}