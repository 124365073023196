import axios from 'axios';
import constants from 'helpers/constants';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const BlogDetail = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [blog, setBlog] = useState([]);
    const [loading, setLoading] = useState(false);
    const { url } = useParams();
    useEffect(() => {
        fetchBlog(url);
    }, []);

    const fetchBlog = async (param) => {
        setLoading(true);
        let url = `${API_URL}get-blog/${param}`;
        const blog = await axios.get(url).then(function (response) {
            setBlog(response.data.data.blogs);
            setLoading(false);
        }).catch(function (error) {
            console.log('error : ', error);
            setLoading(false);
        });
    }

    return (
        <>
            <div className='bg-white float-left w-full md:py-24 md:pb-10 py-12'>
                <div className='container m-auto px-4'>
                    <div className='float-left w-full pt-10'>
                        <div className='float-left w-full font-Nunito_Regular pb-8 relative'>
                            <p className='md:leading-7 md:text-xl text-sm leading-6 text-[#696969] pb-5 font-Nunito_SemiBold'><span className='text-burgandy font-Nunito_SemiBold'>{blog?.author} &nbsp; |</span> &nbsp; {blog?.date}</p>
                            <h2 className='lg:text-[28px] text-[20px] text-black font-Nunito_SemiBold'>{blog?.title}</h2>
                            <span className='w-[70px] h-[2px] float-left bg-burgandy mb-6 mt-2'></span>
                            <div className="clear-both"></div>
                            <img class="w-[230px] sm:block hidden float-left absolute bottom-0 right-5" src={`${process.env.REACT_APP_FRONT_URL}assets/images/aboutimg_shap-02.svg`} alt="" />
                        </div>
                        <div className='float-left w-full my-2 lg:my-0 pb-10 relative h-[590px] rounded-2xl overflow-hidden'>
                            <img className='w-full float-left h-full object-cover' src={blog?.image_url ? blog?.image_url : constants.NO_IMAGE} alt="" />
                        </div>
                        <div className="clear-both"></div>
                        <div className='blog_detail'>
                            {parse(blog?.long_description ? blog?.long_description : '')}
                        </div>

                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>

        </>
    )
};

export default BlogDetail;