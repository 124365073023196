import ReactPaginate from 'react-paginate';

const Pagination = (props) => {
    let pageCount = Math.ceil(props.totalCount / props.perPageRecord);

    return (
        <>
            <ReactPaginate
                forcePage={props.currentPage}
                previousLabel={<i className="fa-solid fa-arrow-left-from-line"></i>}
                nextLabel={<i className="fa-solid fa-arrow-right-from-line"></i>}
                pageCount={pageCount}
                marginPagesDisplayed={0}
                pageRangeDisplayed={0}
                onPageChange={props.handlePageClick}
                containerClassName={'pagination w-full float-left pt-5'}
                pageClassName={'inline-block p-0.5'}
                pageLinkClassName={'w-[36px] h-[36px] block leading-[36px] bg-gray-100 hover:bg-burgandy hover:text-white rounded-md'}
                previousClassName={'inline-block p-0.5'}
                previousLinkClassName={'w-[36px] h-[36px] block leading-[36px] bg-gray-100 hover:bg-burgandy hover:text-white rounded-md'}
                nextClassName={'inline-block p-0.5'}
                nextLinkClassName={'w-[36px] h-[36px] block leading-[36px] bg-gray-100 hover:bg-burgandy hover:text-white rounded-md'}
                activeLinkClassName={'active !bg-burgandy text-white'}
            />
        </>
    );
}

export default Pagination